import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';
import { appConstants } from '../../../shared/constants/constants';
import {
  APIService,
  Couple,
  UpdateSemirremolqueInput,
  UpdateTanqueInput,
  UpdateTractoInput,
} from '../../../app-sync.service';
import { UsersService } from '../../users/users.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { DataTableDirective } from 'angular-datatables';
import { CouplingsService } from '../couplings.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-members-coupled',
  templateUrl: './members-coupled.component.html',
  styleUrls: ['./members-coupled.component.css'],
})
export class MembersCoupledComponent implements OnInit, OnDestroy {
  @ViewChild('coupledTractoModal', { static: false }) coupledTractoModal:
    | TemplateRef<any>
    | undefined;
  entityName: string = 'Vehículos Acoplados';
  dtOptions: DataTables.Settings = {};
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isViewer: boolean = false;
  isGettingCouples: boolean = false;
  imgSrc: { [key: string]: string } = {};
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  couples: Couple[];
  couplesAttributes: string[] = [
    'Tracto',
    'Remolque',
    'Tipo de Remolque',
    'Estado',
    'Centro',
    'Desacoplar',
  ];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private couplesChangedSubscription: Subscription = new Subscription();
  private onDeleteCoupleListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateCoupleListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateCoupleListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private api: APIService,
    private usersService: UsersService,
    private modalsService: ModalsService,
    private feedbacksService: FeedbacksService,
    private couplingsService: CouplingsService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.couples = this.couplingsService.getCouples();
  }

  async ngOnInit(): Promise<void> {
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.isViewer = this.usersService.isViewer;

    this.couplesChangedSubscription =
      this.couplingsService.couplesChanged.subscribe((couples: Couple[]) => {
        this.couples = couples;
      });

    await this.updateCouplesList();

    const couplesFilterInput =
      this.couplingsService.getCouplesFilterForSubscriptions();
    // Subscripción a acoplamientos eliminados
    this.onDeleteCoupleListener = this.api
      .OnDeleteCoupleListener(couplesFilterInput)
      .subscribe((response) => {
        if (response) {
          this.couplingsService.refreshCouples();
        }
      });
    // Subscripción a acoplamientos actualizados
    this.onUpdateCoupleListener = this.api
      .OnUpdateCoupleListener(couplesFilterInput)
      .subscribe((response) => {
        if (response) {
          this.couplingsService.refreshCouples();
        }
      });
    // Subscripción a acoplamientos creados
    this.onCreateCoupleListener = this.api
      .OnCreateCoupleListener(couplesFilterInput)
      .subscribe((response) => {
        if (response) {
          this.couplingsService.refreshCouples();
        }
      });
  }

  /**
   * Llama al método que refresca la lista de acoplamientos.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateCouplesList();
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del acoplamiento.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Elimina un acoplamiento.
   * @param {Couple} couple Acoplamiento a considerar.
   * @return {Promise}
   */
  async onDecoupling(couple: Couple): Promise<void> {
    const tractoIdentification: string = `${parseInt(
      couple.tracto.sapId!
    ).toString()} - ${couple.tracto.licensePlate}`;
    let coupleIdentification: string = '';
    const coupleType: 'tanque' | 'semirremolque' = couple.tanque
      ? 'tanque'
      : 'semirremolque';
    this.modalTitle = appConstants.couple.modalMessages.uncoupling.title;
    this.modalQuestion = appConstants.couple.modalMessages.uncoupling.question
      .replace('_', tractoIdentification)
      .replace('_', coupleType);

    if (coupleType === 'tanque') {
      coupleIdentification = `${parseInt(couple.tanque!.sapId!).toString()} - ${
        couple.tanque!.tanqueId
      }`;
    } else {
      coupleIdentification = `${parseInt(
        couple.semirremolque!.sapId!
      ).toString()} - ${couple.semirremolque!.semirremolqueId}`;
    }
    this.modalQuestion = this.modalQuestion.replace('_', coupleIdentification);
    this.modalMessage =
      appConstants.couple.modalMessages.uncoupling.message.replace(
        '_',
        coupleType
      );

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.coupledTractoModal
    );

    if (modalResult) {
      // 1. Eliminamos el acoplamiento
      await this.api
        .DeleteCouple({ coupleId: couple.coupleId })
        .then((): void => {
          this.feedbacksService.showFeedback(
            `Acople ${couple.coupleId} eliminado`,
            'info'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al eliminar el acople ${couple.coupleId}`
          );
        });

      // 2. Cambiamos el estado del tracto a Procesando y eliminamos el acople.
      const updateTractoInput: UpdateTractoInput = {
        tractoId: couple.tracto.tractoId,
        tractoCoupleCoupleId: null,
        status: `${couple.business}_${appConstants.entity.codes.inProcess}`,
      };

      await this.api
        .UpdateTracto(updateTractoInput)
        .then((): void => {
          this.feedbacksService.showFeedback(
            `Tracto ${tractoIdentification} desacoplado. Actualizando`,
            'info'
          );
          // Refrescamos la lista de tractos acoplados
          this.updateCouplesList();
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al desacoplar tracto ${tractoIdentification}`
          );
        });
      // 3. Dependiendo de si está acoplado a un Tanque o un Semirremolque cambiamos
      // el estado del mismo a Procesando y eliminamos el acople con el tracto.
      if (coupleType === 'tanque') {
        const updateTanqueInput: UpdateTanqueInput = {
          tanqueId: couple.tanque!.tanqueId,
          tanqueCoupleCoupleId: null,
          status: `${couple.business}_${appConstants.entity.codes.inProcess}`,
        };

        await this.api
          .UpdateTanque(updateTanqueInput)
          .then((): void => {
            this.feedbacksService.showFeedback(
              `Tanque ${coupleIdentification} desacoplado. Actualizando`,
              'info'
            );
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al desacoplar tanque ${coupleIdentification}:`
            );
          });
      } else {
        const updateSemirremolqueInput: UpdateSemirremolqueInput = {
          semirremolqueId: couple.semirremolque!.semirremolqueId,
          semirremolqueCoupleCoupleId: null,
          status: `${couple.business}_${appConstants.entity.codes.inProcess}`,
        };

        await this.api
          .UpdateSemirremolque(updateSemirremolqueInput)
          .then((): void => {
            this.feedbacksService.showFeedback(
              `Semirremolque ${coupleIdentification} desacoplado. Actualizando`,
              'info'
            );
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al desacoplar semirremolque ${coupleIdentification}`
            );
          });
      }
    }
  }

  /**
   * Selecciona la imagen a mostrar en el botón de desacople.
   * @param {string} licensePlate Patente del Tracto.
   * @param {'over' | 'out'} action Acción sobre el botón.
   */
  onMouseOverOrOut(licensePlate: string, action: 'over' | 'out'): void {
    if (action === 'over') {
      this.imgSrc[licensePlate] = 'assets/images/icons/uncoupling-white.svg';
    } else {
      this.imgSrc[licensePlate] = 'assets/images/icons/uncoupling-danger.svg';
    }
  }

  /**
   * Refresca la lista de acoplamientos.
   * @private
   * @return {Promise}
   */
  private async updateCouplesList(): Promise<void> {
    this.isGettingCouples = true;
    await this.couplingsService.refreshCouples().then((): void => {
      this.isGettingCouples = false;
    });
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {Boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'decouple') {
      show = this.isAdmin || this.isCarrier;
    }

    return show;
  }

  /**
   * Determina, con base al rol del usuario, si debe ocultarse una columna de la tabla.
   * @param {string} column Nombre de la columna.
   * @return {Boolean}
   */
  hideColumn(column: string): boolean {
    let hide: boolean = false;

    switch (column) {
      case 'Desacoplar':
        hide = !(this.isAdmin || this.isCarrier);
        break;
      case 'Centro':
        hide = !this.isViewer;
        break;
      default:
        break;
    }
    return hide;
  }

  /**
   * Define si el botón de Desacoplar estará activo o no
   * según el estado del acoplamiento.
   * @param {string} status Estado del acoplamiento.
   */
  disableDecouplingButton(status: string): boolean {
    // Si el acople está "Procesando" o "Por aprobar" no se permite desacoplar.
    return (
      status.endsWith(appConstants.entity.codes.toBeApproved) ||
      status.endsWith(appConstants.entity.codes.inProcess)
    );
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.couplesChangedSubscription.unsubscribe();
    this.onDeleteCoupleListener.unsubscribe();
    this.onCreateCoupleListener.unsubscribe();
    this.onUpdateCoupleListener.unsubscribe();
    console.log('members-coupled.component subscriptions removed.');
  }
}
