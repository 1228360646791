<div class="row align-items-end">
  <div class="col-sm-6 text-center" *ngIf="!isGettingTanque">
    <div>
      <small id="tanque-dates" class="text-muted">
        Creado: {{ tanque.createdAt | dateFormat }} <br />
        Actualizado: {{ tanque.updatedAt | dateFormat }}
      </small>
    </div>
    <div class="btn-group divCenter">
      <button
        id="tanque-details-approve"
        type="button"
        class="btn btn-outline-primary my-2"
        (click)="onApproveTanque()"
        *ngIf="tanqueNeedsApproval && showButton('approve')"
      >
        Aprobar
      </button>
      <button
        id="tanque-details-reject"
        type="button"
        class="btn btn-outline-danger my-2"
        (click)="onRejectTanque()"
        *ngIf="tanqueCanBeRejected && showButton('reject')"
      >
        Rechazar
      </button>
    </div>
  </div>
  <div class="col-sm-6 text-end">
    <div class="btn-group" style="padding-top: 60px">
      <div ngbDropdown class="d-inline-block">
        <button
          type="button"
          class="btn btn-outline-primary dropdownButton mx-2"
          id="tanque-details-actions"
          *ngIf="!isGettingTanque && showButton('actions')"
          ngbDropdownToggle
        >
          Acciones
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownActions">
          <a
            id="tanque-details-edit"
            *ngIf="!isGettingTanque && showButton('edit')"
            (click)="onEditTanque()"
            class="dropdown-item"
            ngbDropdownItem
          >
            Actualizar documentos</a
          >
          <div class="dropdown-divider"></div>
          <a
            id="tanque-details-archive"
            *ngIf="!isGettingTanque && showButton('archive')"
            (click)="onArchiveTanque()"
            ngbDropdownItem
          >
            Archivar</a
          >
          <a
            id="tanque-details-delete"
            *ngIf="!isGettingTanque && showButton('delete')"
            (click)="onDeleteTanque()"
            ngbDropdownItem
          >
            Borrar</a
          >
        </div>
      </div>
      <button
        id="tanque-details-refresh"
        class="btn btn-outline-secondary"
        *ngIf="!isGettingTanque"
        (click)="onRefreshTanque()"
      >
        <span>
          <img
            ngSrc="assets/images/icons/actualizar.svg"
            height="25"
            width="25"
            alt="Actualizar"
          />
        </span>
      </button>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-sm-6 text-center my-5" *ngIf="isGettingTanque">
    <app-spinner message="Cargando detalles del vehículo..."></app-spinner>
  </div>
  <div
    class="col-sm-6 box-details-cab"
    [ngStyle]="{ border: '3px solid ' + getColor() }"
    *ngIf="!isGettingTanque"
  >
    <div class="div-box-sap-id">
      <h4 [ngStyle]="{ color: getColor(), 'font-weight': 'bold' }">
        SAP ID: {{ tanque.sapId! | sapId }}
      </h4>
    </div>
    <div style="display: inline">
      <p><strong>Transportista</strong>: <br />{{ tanque.carrier!.name }}</p>
      <p><strong>Patente</strong>: <br />{{ tanque.licensePlate }}</p>
      <p>
        <strong>Acoplamiento</strong>: <br />
        <span *ngIf="tanqueIsCoupled"
          >{{ coupleType }} {{ coupleIdentification }}</span
        >
        <span *ngIf="!tanqueIsCoupled">Desacoplado</span>
      </p>
    </div>
    <div *ngIf="!tanqueIsCoupled" class="text-danger divCenter">
      <p><strong>Desacoplado</strong></p>
    </div>
    <div class="divCenter" style="text-align: center">
      <p *ngIf="showBlockingMotives">
        <strong
          >Bloqueado el
          {{
            tanque.lastStatusInformedAt || tanque.updatedAt
              | dateFormat
              | dateWithoutSeconds
          }}
          por</strong
        >:<br />
        <span
          class="badge border rounded-4 text-bg-light mx-1"
          *ngFor="let motive of tanque.blockingMotives"
        >
          {{ motive }}
        </span>
      </p>
    </div>

    <div class="divCenter">
      <h5>
        <span
          class="badge"
          [ngStyle]="{ 'background-color': getColor() }"
          style="width: 200px; border-radius: 60px"
        >
          <span>
            <img
              [src]="getImageSrc(tanque.status)"
              height="25"
              width="25"
              alt="Estado"
            />
          </span>
          {{ tanque.status | master : "VEHICLES#STATUSES" }}</span
        >
      </h5>
      <div *ngIf="hasComment">
        <label
          class="custom-file-upload pt-2 mx-1 float-end text-secondary"
          style="cursor: pointer"
        >
          <a id="entity-comment" title="Comentario" (click)="onViewComment()">
            <span class="material-symbols-outlined">comment</span>
          </a>
        </label>
      </div>
    </div>
    <div class="divCenter mb-2" *ngIf="hasComment && showComment">
      <div class="collapse comment" [ngClass]="showCommentClass()">
        <div class="card card-body">
          {{ tanque.comment }}
        </div>
      </div>
    </div>

    <div class="divCenter">
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasToExpireDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            tanque.documentsToExpire
          }}</span>
          por vencer</span
        >
      </h6>
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasExpiredDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            tanque.documentsExpired
          }}</span>
          vencido</span
        >
      </h6>
    </div>

    <div ngbAccordion style="padding: 10px">
      <div ngbAccordionItem [collapsed]="true">
        <h2 ngbAccordionHeader>
          <button id="tanque-details-accordion" ngbAccordionButton>
            Ver detalles
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="table-container">
            <ng-template>
              <table class="table table-hover">
                <caption>
                  Detalles del Vehículo
                </caption>
                <tbody>
                  <tr>
                    <th scope="row">Centro</th>
                    <td>{{ tanque.originalCenter! | master : "CENTERS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Sub-Negocio</th>
                    <td>
                      {{ tanque.subBusiness | master : "SUB_BUSINESSES" }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Marca</th>
                    <td>{{ tanque.brand | master : "VEHICLES#BRANDS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Modelo</th>
                    <td>{{ tanque.model }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Año de Fabricación</th>
                    <td>{{ tanque.year }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Antigüedad</th>
                    <td>
                      {{ currentYear - tanque.year }} año{{
                        currentYear - tanque.year === 1 ? "" : "s"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Color</th>
                    <td>{{ tanque.color | master : "VEHICLES#COLORS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo</th>
                    <td>{{ tanque.type | master : "TANQUES#TYPES" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Capacidad</th>
                    <td>
                      {{ tanque.capacity | master : "TANQUES#CAPACITIES" }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Alto x Ancho x Largo (metros)</th>
                    <td>
                      {{ tanque.height }} x {{ tanque.width }} x
                      {{ tanque.length }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Mamparo</th>
                    <td>
                      {{
                        tanque.bulkheadType! | master : "TANQUES#BULKHEAD_TYPES"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Tiene Bomba</th>
                    <td>{{ tanque.hasPump ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Usado en Minería</th>
                    <td>{{ tanque.forMining ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Acreditación Ranchera</th>
                    <td>{{ tanque.hasRanchAccreditation ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Es Spot</th>
                    <td>{{ tanque.isSpot ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">GPS IMEI</th>
                    <td>{{ tanque.gpsImei }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Cámara IMEI</th>
                    <td>{{ tanque.cameraImei }}</td>
                  </tr>
                  <tr>
                    <th class="align-middle" scope="row">Centro de Apoyo</th>
                    <td>
                      <div>
                        <label
                          *ngIf="showButton('edit-center')"
                          class="float-start me-1"
                          style="cursor: pointer"
                        >
                          <a
                            id="update-center"
                            title="Actualizar Centro de Apoyo"
                            (click)="onUpdateTanque()"
                          >
                            <span
                              class="material-symbols-outlined text-secondary"
                              >edit_square</span
                            >
                          </a>
                        </label>
                        {{ tanque.center | master : "CENTERS" }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center">
                <img
                  [src]="capacityImage()"
                  alt="Tanque"
                  height="200"
                  width="256"
                />
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <app-documents></app-documents>
  </div>
</div>

<!-- Modals -->
<ng-template #tanqueModal let-c="close" let-d="dismiss">
  <app-modal-with-comment
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [showCommentForm]="modalWithComment"
    [mandatoryComment]="isBeingRejected"
    [commentForm]="commentForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-with-comment>
</ng-template>

<!-- Update Attribute Modal -->
<ng-template #updateTanqueAttributeModal let-c="close" let-d="dismiss">
  <app-modal-change-attributes
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [attributeForm]="updateAttributeForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-change-attributes>
</ng-template>

<!-- Archive Modal -->
<ng-template #archiveTanqueModal let-c="close" let-d="dismiss">
  <app-modal-for-archiving
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [archiveForm]="archiveForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-for-archiving>
</ng-template>
