import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorsService } from '../../services/validators.service';
import { MasterService } from '../../../pages/master/master.service';
import { Master } from '../../../app-sync.service';
import { appConstants } from '../../constants/constants';

@Component({
  selector: 'app-modal-for-exclusions',
  templateUrl: './modal-for-exclusions.component.html',
})
export class ModalForExclusionsComponent implements OnInit {
  @Input() modalTitle: string = '';
  @Input() modalQuestion: string = '';
  @Input() modalMessage: string = '';
  @Input() exclusionForm: FormGroup = new FormGroup({});
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();
  readonly master: any = {};
  readonly exclusionMotives: Array<Master> = [];
  tomorrow: string;
  get notificationControl() {
    return this.exclusionForm;
  }

  constructor(
    private validatorsService: ValidatorsService,
    private masterService: MasterService
  ) {
    let tomorrow: Date = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.tomorrow = tomorrow.toISOString().split('T')[0];
    this.master = this.masterService.getMaster();
    this.exclusionMotives = this.master['DRIVERS#EXCLUSION_MOTIVES'];
  }
  ngOnInit(): void {
    this.exclusionForm.get('exclusionMotive')?.setValue('');
  }

  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }
  onChangePermanent() {
    if (this.exclusionForm.get('isPermanent')?.value) {
      this.exclusionForm.get('excludeDuration')?.disable();
    } else {
      this.exclusionForm.get('excludeDuration')?.enable();
    }
  }
  onChangeExclusionMotive(): void {
    // Si el motivo es "Otro" debe agregar un comentario obligatoriamente.
    if (
      this.exclusionForm.value.exclusionMotive.endsWith(
        appConstants.motives.exclude.other
      )
    ) {
      this.exclusionForm.controls['comment'].addValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
    } else {
      this.exclusionForm.controls['comment'].removeValidators([
        Validators.required,
        Validators.minLength(1),
      ]);
    }
    this.exclusionForm.get('comment')?.updateValueAndValidity();
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }
}
