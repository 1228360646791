import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import { APIService, Semirremolque } from '../../../app-sync.service';
import { SemirremolquesService } from '../semirremolques.service';
import { UsersService } from '../../users/users.service';
import { DataTableDirective } from 'angular-datatables';
import { DocumentsService } from '../../documents/documents.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-semirremolques-list',
  templateUrl: './semirremolques-list.component.html',
  styleUrls: ['./semirremolques-list.component.css'],
})
export class SemirremolquesListComponent implements OnInit, OnDestroy {
  entityName: string = 'Semirremolques';
  dtOptions: DataTables.Settings = {};
  isGettingSemirremolques: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isViewer: boolean = false;
  isCarrier: boolean = false;
  semirremolques: Semirremolque[];
  semirremolquesAttributes: string[] = [
    'SAP ID',
    'Estado',
    'Patente',
    'Transportista',
    'Centro',
    'Alertas',
    'Bloqueos',
  ];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private semirremolquesChangedSubscription: Subscription = new Subscription();
  private onDeleteSemirremolqueListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateSemirremolqueListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateSemirremolqueListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private semirremolquesService: SemirremolquesService,
    private api: APIService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private documentService: DocumentsService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.semirremolques = this.semirremolquesService.getSemirremolques();
  }

  async ngOnInit(): Promise<void> {
    this.semirremolquesChangedSubscription =
      this.semirremolquesService.semirremolquesChanged.subscribe(
        (semirremolques: Semirremolque[]) => {
          this.semirremolques = semirremolques;
        }
      );

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isViewer = this.usersService.isViewer;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateSemirremolquesList();

    // Una vez cargada la lista de vehículos definimos los filtros para subscripciones
    const subscriptionSemirremolquesFilter =
      this.semirremolquesService.getSemirremolquesFilterForSubscriptions();
    // Subscripción a Vehículos eliminados
    this.onDeleteSemirremolqueListener = this.api
      .OnDeleteSemirremolqueListener(subscriptionSemirremolquesFilter)
      .subscribe((response) => {
        if (response) {
          this.semirremolquesService.refreshSemirremolques();
        }
      });
    // Subscripción a Vehículos actualizados
    this.onUpdateSemirremolqueListener = this.api
      .OnUpdateSemirremolqueListener(subscriptionSemirremolquesFilter)
      .subscribe((response) => {
        if (response) {
          this.semirremolquesService.refreshSemirremolques();
        }
      });
    // Subscripción a Vehículos creados
    this.onCreateSemirremolqueListener = this.api
      .OnCreateSemirremolqueListener(subscriptionSemirremolquesFilter)
      .subscribe((response) => {
        if (response) {
          this.semirremolquesService.refreshSemirremolques();
        }
      });
  }

  /**
   * Navega a al formulario de creación de Semirremolque.
   */
  onNewSemirremolque(): void {
    this.router
      .navigate(['new'], { relativeTo: this.route })
      .then(() => console.log('navigate to new'));
  }

  /**
   * Llama al método que refresca la lista de Semirremolques.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateSemirremolquesList();
  }

  /**
   * Define el semirremolque que está seleccionado.
   * y navega al detalle del mismo.
   * @param {Semirremolque} semirremolque Semirremolque seleccionado de la lista.
   */
  onSelectedSemirremolque(semirremolque: Semirremolque): void {
    this.documentService.isRefreshFilter.next(true);
    this.semirremolquesService.setSelectedSemirremolque(semirremolque);
    this.router
      .navigate([semirremolque.semirremolqueId], { relativeTo: this.route })
      .then(() => console.log(`navigate to ${semirremolque.semirremolqueId}`));
  }

  /**
   * Indica si un semirremolque tiene documentos por vencer.
   * @param {Semirremolque} semirremolque Semirremolque de la lista.
   * @return {boolean} Boolean que indica si el Semirremolque tiene documentos por vencer.
   */
  hasToExpireDocuments(semirremolque: Semirremolque): boolean {
    const docNumber = semirremolque.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un semirremolque tiene documentos vencidos.
   * @param {Semirremolque} semirremolque Semirremolque de la lista.
   * @return {boolean} Boolean que indica si el Semirremolque tiene documentos vencidos.
   */
  hasExpiredDocuments(semirremolque: Semirremolque): boolean {
    const docNumber = semirremolque.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del Semirremolque.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Refresca la lista de semirremolques.
   * @private
   * @return {Promise}
   */
  private async updateSemirremolquesList(): Promise<void> {
    this.isGettingSemirremolques = true;
    await this.semirremolquesService.refreshSemirremolques().then((): void => {
      this.isGettingSemirremolques = false;
    });
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'create') {
      show = this.isAdmin || this.isCarrier;
    }
    return show;
  }

  /**
   * Determina, con base al rol del usuario, si debe ocultarse una columna de la tabla.
   * @param {string} column Nombre de la columna.
   * @return {Boolean}
   */
  hideColumn(column: string): boolean {
    let hide: boolean = false;

    if (column === 'Transportista') {
      hide = !(this.isAdmin || this.isApprover || this.isViewer);
    }
    return hide;
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.semirremolquesChangedSubscription.unsubscribe();
    this.onDeleteSemirremolqueListener.unsubscribe();
    this.onCreateSemirremolqueListener.unsubscribe();
    this.onUpdateSemirremolqueListener.unsubscribe();
    console.log('semirremolques-list.component subscriptions removed.');
  }
}
