import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidatorsService } from '../../services/validators.service';
import { MasterService } from '../../../pages/master/master.service';
import { Master } from '../../../app-sync.service';
import { environment } from '../../../../environments/environment';
import { appConstants } from '../../constants/constants';
import { ArchivesService } from '../../services/archives.service';

@Component({
  selector: 'app-modal-for-archiving',
  templateUrl: './modal-for-archiving.component.html',
  styleUrls: ['./modal-for-archiving.component.css'],
})
export class ModalForArchivingComponent implements OnInit {
  @Input() modalTitle: string = '';
  @Input() modalQuestion: string = '';
  @Input() modalMessage: string = '';
  @Input() isDriver: boolean = false;
  @Input() archiveForm: FormGroup = new FormGroup({});
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();
  readonly master: any = {};
  archiveMotives: Array<Master> = [];
  documentRequired: boolean = false;
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';

  get notificationsControl() {
    return this.archiveForm;
  }
  get documentsControl() {
    // El formulario puedo no tener documentos.
    if (this.archiveForm.get('documents') === null) {
      return [];
    }
    return (<FormArray>this.archiveForm.get('documents')).controls;
  }

  constructor(
    private validatorsService: ValidatorsService,
    private masterService: MasterService,
    private archivesService: ArchivesService
  ) {
    this.master = this.masterService.getMaster();
    this.archiveMotives = this.master['VEHICLES#ARCHIVE_MOTIVES'];
  }

  ngOnInit(): void {
    this.archiveForm.get('motiveForArchiving')?.setValue('');
    this.documentRequired = !!this.archiveForm.get('documents');
    if (this.isDriver) {
      this.archiveMotives = this.master['DRIVERS#ARCHIVE_MOTIVES'];
    }
  }

  /**
   * Valida el motivo para archivar para determinar
   * si el comentario debe ser obligatorio o no.
   */
  onChangeMotiveForArchiving(): void {
    // Si el motivo es "Otro" debe agregar un comentario obligatoriamente.
    if (
      this.archiveForm.value.motiveForArchiving.endsWith(
        appConstants.motives.archive.other
      )
    ) {
      this.archiveForm.controls['comment'].addValidators([
        Validators.required,
        Validators.minLength(5),
      ]);
    } else {
      this.archiveForm.controls['comment'].removeValidators([
        Validators.required,
        Validators.minLength(5),
      ]);
    }
    this.archiveForm.get('comment')?.updateValueAndValidity();
  }

  /**
   * Asigna el archivo al control documentSourceFile
   * del documento.
   * @param {Event} event Evento input.
   * @param {number} index Índice del documento en el arreglo documents.
   */
  onChangeDocumentFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'documentSourceFile'
    const target = event.target as HTMLInputElement;
    console.log(' --- onChangeDocumentFile ---');
    console.log(target);
    console.log(target.files![0]);
    if (target.files!.length > 0) {
      const file: File = target.files![0];
      (<FormArray>this.archiveForm.get('documents'))
        .at(index)
        .patchValue({ documentSourceFile: file });
    }
  }

  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.archivesService.setArchiving(false);
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.archiveForm);
  }
}
