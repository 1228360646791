import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  APIService,
  Document,
  EvaluatedRozForm,
  ModelEvaluatedRozFormFilterInput,
  ModelIDKeyConditionInput,
} from 'src/app/app-sync.service';
import { appConstants } from 'src/app/shared/constants/constants';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { DocumentsService } from '../../documents/documents.service';
import { RtcEvaluationsService } from '../../rtc-evaluations/rtc-evaluations.service';
import { UsersService } from '../../users/users.service';
import { MasterService } from '../../master/master.service';
import { ApiRequestsService } from 'src/app/shared/services/api-requests.service';
import { RTCHistoryFileParams } from 'src/app/shared/interfaces/rtc-parameters';
import { CommonsService } from '../../../shared/services/commons.service';

@Component({
  selector: 'app-historial-rtc',
  templateUrl: './historial-rtc.component.html',
  styleUrls: ['./historial-rtc.component.css'],
})
export class HistorialRTCComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  listEvaluations: any[] = []; //TODO Aquí definir el tipo de los  datos
  filteredEvaluations: any[] = [];
  filterForm: FormGroup;
  isGettingEvaluations: boolean = false;
  modalTitle: string = '';
  pdfSource: string = '';
  @ViewChild('documentViewer', { static: false }) documentViewer:
    | TemplateRef<any>
    | undefined;
  isGettingURL: boolean = false;
  business: string = '';
  uniqueFormIds: any[] = [];
  readonly master: any = {};
  CENTERS: any;
  CARRIERS: any[];
  CARRIERSINDEX: any;
  results: Array<{ display: string; value: string }> = [
    { display: 'Aprobado', value: 'APPROVED' },
    { display: 'Rechazado', value: 'REPROVED' },
  ];
  startDate: string = '';
  endDate: string = '';
  maxDate: string = '';
  minDate: string = '';
  constructor(
    private fb: FormBuilder,
    private api: APIService,
    private rtcEvaluationsService: RtcEvaluationsService,
    private documentsService: DocumentsService,
    private modalsService: ModalsService,
    private usersService: UsersService,
    private masterService: MasterService,
    private apiService: ApiRequestsService,
    private commonsService: CommonsService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.filterForm = this.fb.group({
      dateRangeStart: [''],
      dateRangeEnd: [''],
      plate: this.fb.control<string>(''),
      carrier: [''],
      center: [''],
      result: [''],
      sapID: [''],
    });
    this.master = this.masterService.getMaster();
    this.CENTERS = this.master['CENTERS'];
    this.CARRIERS = this.master['CARRIERS'];
    this.CARRIERSINDEX = this.CARRIERS.reduce((acc, carrier) => {
      acc[carrier.valueId] = carrier;
      return acc;
    });
  }
  ngOnInit(): void {
    this.getDate30DaysAgo();
    this.isGettingEvaluations = true;
    this.usersService.business.subscribe({
      next: (business) => {
        this.business = business;
        this.getEvaluations();
      },
    });
  }
  /**
   * obtiene el historial
   * @param nextToken
   */
  getEvaluations(nextToken?: string): void {
    this.api
      .EvaluatedRozFormsByBusinessAndEvaluatedAt(
        this.business.toUpperCase(),
        {
          between: [this.startDate, this.endDate],
        },
        undefined,
        undefined,
        100,
        nextToken
      )
      .then((list) => {
        this.listEvaluations.push(...list.items);
        if (list.nextToken) {
          this.getEvaluations(list.nextToken);
        } else {
          this.isGettingEvaluations = false;
        }
      });
  }

  /**
   * ejecuta llamadas para filtrar por fechas si es necesario
   * @param nextToken
   */
  onFilter(nextToken?: string): void {
    this.isGettingEvaluations = true;
    this.startDate = this.filterForm.get('dateRangeStart')?.value;
    this.endDate = this.commonsService.addDays(
      this.filterForm.get('dateRangeEnd')?.value,
      1
    );
    let carrier = this.filterForm.get('carrier')?.value;
    let center = this.filterForm.get('center')?.value;
    let status = this.filterForm.get('result')?.value;
    let plate = this.filterForm.get('plate')?.value;
    let sapID = this.filterForm.get('sapID')?.value;
    let filter: ModelEvaluatedRozFormFilterInput = {};
    let evaluatedAt: ModelIDKeyConditionInput = {
      between: [this.startDate, this.endDate],
    };
    filter.status = status ? { eq: status } : undefined;
    filter.sapId = sapID ? { contains: sapID } : undefined;
    filter.formId = plate ? { contains: plate } : undefined;
    if (carrier) {
      filter.center = center ? { eq: center } : undefined;
      this.getFormsByCarrier(carrier, evaluatedAt, filter, nextToken);
    } else if (center) {
      this.getFormsByCenter(center, evaluatedAt, filter, nextToken);
    } else {
      this.getFormsByBusiness(evaluatedAt, filter, nextToken);
    }
  }

  getFormsByCarrier(
    carrier: string,
    evaluatedAt: ModelIDKeyConditionInput,
    filter: ModelEvaluatedRozFormFilterInput,
    nextToken?: string
  ) {
    this.api
      .EvaluatedRozFormsByCarrierAndEvaluatedAt(
        carrier,
        evaluatedAt,
        undefined,
        filter,
        100,
        nextToken
      )
      .then((response) => {
        if (response.items) {
          this.handleResponseAndApplyFilters(response, nextToken);
        } else {
          console.log('no hay data en los rangos seleccionados');
        }
      });
  }
  getFormsByCenter(
    center: string,
    evaluatedAt: ModelIDKeyConditionInput,
    filter: ModelEvaluatedRozFormFilterInput,
    nextToken?: string
  ) {
    this.api
      .EvaluatedRozFormsByCenterAndEvaluatedAt(
        center,
        evaluatedAt,
        undefined,
        filter,
        100,
        nextToken
      )
      .then((response) => {
        if (response.items) {
          this.handleResponseAndApplyFilters(response, nextToken);
        } else {
          console.log('no hay data en los rangos seleccionados');
        }
      });
  }
  getFormsByBusiness(
    evaluatedAt: ModelIDKeyConditionInput,
    filter: ModelEvaluatedRozFormFilterInput,
    nextToken?: string
  ) {
    this.api
      .EvaluatedRozFormsByBusinessAndEvaluatedAt(
        this.business.toUpperCase(),
        evaluatedAt,
        undefined,
        filter,
        100,
        nextToken
      )
      .then((response) => {
        if (response.items) {
          this.handleResponseAndApplyFilters(response, nextToken);
        } else {
          console.log('no hay data en los rangos seleccionados');
        }
      });
  }

  handleResponseAndApplyFilters(list: any, nextToken?: string) {
    if (!nextToken) {
      this.listEvaluations = [];
      this.filteredEvaluations = [];
    }
    this.listEvaluations.push(...list.items);
    if (list.nextToken) {
      this.onFilter(list.nextToken);
    } else {
      this.listEvaluations.forEach((element) => {
        let splitted = element.formId.split('#');
        element.vehicleType = splitted[2];
        element.plate = splitted[3];
      });
      this.localFilters();
    }
  }

  /**
   * aplica los filtros de manera local a la respuesta del servidor
   * @returns {void}
   */
  localFilters(): void {
    this.isGettingEvaluations = true;
    this.filteredEvaluations = [];
    this.filteredEvaluations = [...this.listEvaluations];
    this.isGettingEvaluations = false;
  }
  /**
   * Llama al servicio de descarga de documentos.
   * @param {EvaluatedRozForm} evaluation  a descargar.
   * @return {Promise}
   */
  async onDownloadDocument(evaluation: any): Promise<void> {
    let document: Document = appConstants.document.initialization;

    // Agregamos la fecha al nombre en formato DDMMYYYYHHMMSS
    const valueDate: Date = new Date(evaluation.updatedAt);
    const dateArray: string[] = valueDate.toLocaleDateString().split('/');
    let formattedDate: string = dateArray[1].padStart(2, '0');
    formattedDate += dateArray[0].padStart(2, '0');
    formattedDate += dateArray[2];
    formattedDate += valueDate.toTimeString().split(' ')[0].replaceAll(':', '');

    document.name = `Evaluación RTC - ${formattedDate}`;
    document.s3Path = evaluation.s3Path;
    switch (evaluation.vehicleType.toUpperCase()) {
      case 'VEHICLE':
        document.vehicleDocumentsVehicleId = evaluation.plate;
        break;
      case 'ENVASADO':
        document.envasadoDocumentsEnvasadoId = evaluation.plate;
        break;
      case 'CISTERNA':
        document.cisternaDocumentsCisternaId = evaluation.plate;
        break;
      case 'TRACTO':
        document.tractoDocumentsTractoId = evaluation.plate;
        break;
      case 'TANQUE':
        document.tanqueDocumentsTanqueId = evaluation.plate;
        break;
      case 'SEMIRREMOLQUE':
        document.semirremolqueDocumentsSemirremolqueId = evaluation.plate;
        break;
      default:
        console.log(
          `${evaluation.vehicleType} no encontrado entre las opciones.`
        );
        break;
    }
    await this.documentsService.downloadDocument(document);
  }

  /**
   * Abre el modal de visualización de un documento.
   * @param {EvaluatedRozForm} evaluation Documento a visualizar.
   * @return {Promise}
   */
  async onViewDocument(evaluation: EvaluatedRozForm): Promise<void> {
    this.isGettingURL = true;
    let document: Document = appConstants.document.initialization;

    this.modalTitle = 'Evaluación RTC';
    document.name = this.modalTitle;
    document.s3Path = evaluation.s3Path;

    await this.documentsService
      .getDocumentPreSignedURL(document)
      .then(async (): Promise<void> => {
        this.pdfSource = this.documentsService.documentPreSignedURL;
        await this.modalsService.showModal(
          <TemplateRef<any>>this.documentViewer,
          true
        );
      });
  }
  pageRendered(): void {
    console.log('Documento cargado.');
    this.isGettingURL = false;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el estado de la evaluación.
   * @param {string} status Estado de la evaluación.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.rtcEvaluationsService.getImageSrc(status);
  }

  downloadExcel() {
    let startDate = this.filterForm.get('dateRangeStart')?.value;
    let endDate = this.commonsService.addDays(
      this.filterForm.get('dateRangeEnd')?.value,
      1
    );
    let sap_id = this.filterForm.get('sapID')?.value;
    let carrier = this.filterForm.get('carrier')?.value;
    let center = this.filterForm.get('center')?.value;
    let status = this.filterForm.get('result')?.value;
    let plate = this.filterForm.get('plate')?.value;
    let parameters: RTCHistoryFileParams = {
      startDate,
      endDate,
      sap_id,
      carrier,
      center,
      status,
      license_plate: plate,
      business: this.business.toUpperCase(),
    };

    this.apiService.downloadExcelRTCHistoryFile(parameters);
  }
  cleanFilters() {
    this.isGettingEvaluations = true;
    this.filteredEvaluations = [];
    this.listEvaluations = [];
    this.uniqueFormIds = [];
    this.filterForm = this.fb.group({
      dateRangeStart: [''],
      dateRangeEnd: [''],
      plate: [''],
      carrier: [''],
      center: [''],
      result: [''],
      sapID: [''],
    });
    this.getDate30DaysAgo();
    this.getEvaluations();
  }
  getDate30DaysAgo(): void {
    const date = new Date();
    const minDate = new Date();
    minDate.setMonth(minDate.getMonth() - 3);
    minDate.setDate(1);
    this.minDate = minDate.toISOString().split('T')[0];
    this.endDate = date.toISOString().split('T')[0];
    date.setDate(date.getDate() - 30);
    this.startDate = date.toISOString().split('T')[0];
    this.maxDate = this.endDate;
    this.filterForm.get('dateRangeEnd')?.setValue(this.endDate);
    this.filterForm.get('dateRangeStart')?.setValue(this.startDate);
  }
}
