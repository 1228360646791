<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 text-center my-5">
      <div class="alert alert-info" role="alert">
        <h4 class="alert-heading">Por favor, seleccione un conductor</h4>
        <hr />
        <p>
          Para ver detalles o gestionar un vehículo, seleccione su RUT en la
          lista de la izquierda.
        </p>
      </div>
    </div>
  </div>
</div>
