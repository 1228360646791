<div class="row">
  <div class="col-sm-12 text-end">
    <div class="btn-group">
      <div ngbDropdown class="d-inline-block">
        <button
          type="button"
          class="btn btn-outline-primary dropdownButton mx-2"
          id="user-details-actions"
          ngbDropdownToggle
        >
          Acciones
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownActions">
          <a
            id="user-details-edit"
            (click)="onEditUser()"
            class="dropdown-item"
            ngbDropdownItem
          >
            Editar</a
          >
          <a
            id="user-details-activate"
            *ngIf="isBlocked"
            (click)="onActivateUser()"
            ngbDropdownItem
          >
            Activar</a
          >
          <a
            id="user-details-block"
            (click)="onBlockUser()"
            *ngIf="!isBlocked"
            ngbDropdownItem
          >
            Bloquear</a
          >
          <div class="dropdown-divider"></div>
          <a
            id="user-details-delete"
            (click)="onDeleteUser(user)"
            ngbDropdownItem
          >
            Borrar</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
<hr />
<div class="row">
  <div class="text-center my-5" *ngIf="isGettingUser">
    <app-spinner message="Cargando detalles del usuario..."></app-spinner>
  </div>
  <div class="col-xs-12" *ngIf="!isGettingUser">
    <div class="float-end">
      <h5>
        <span class="badge" [ngStyle]="{ 'background-color': getColor() }">
          <span>
            <img
              [src]="getImageSrc(user.status)"
              height="25"
              width="25"
              alt="Estado"
            />
          </span>
          {{ user.status | master : "USERS#STATUSES" }}</span
        >
      </h5>
    </div>
    <h1>{{ user.firstName + " " + user.lastName }}</h1>
    <p>
      <strong>Empresa</strong>:
      {{ user.company | master : "CARRIERS" : user.company }}
    </p>
    <p><strong>RUT</strong>: {{ user.rut }}</p>
    <hr />
    <table class="table table-hover">
      <caption>
        Detalles del Usuario
      </caption>
      <tbody>
        <tr>
          <th scope="row">Teléfono</th>
          <td>{{ user.phone | phone }}</td>
        </tr>
        <tr>
          <th scope="row">E-mail</th>
          <td>{{ user.email }}</td>
        </tr>
        <tr>
          <th scope="row">Grupo</th>
          <td>{{ user.authGroup | master : "USERS#GROUPS" }}</td>
        </tr>
        <tr>
          <th scope="row">Negocios</th>
          <td>{{ listBusinessWithAccess(user.hasAccessTo) }}</td>
        </tr>
        <tr *ngIf="isViewer">
          <th scope="row">Centros</th>
          <td>{{ listCentersWithAccess(user.centers) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="text-center">
      <small id="user-dates" class="text-muted"
        >Creado: {{ user.createdAt | dateFormat | dateWithoutSeconds }} |
        Actualizado:
        {{ user.updatedAt | dateFormat | dateWithoutSeconds }}</small
      >
    </div>
  </div>
</div>

<!-- Modals -->
<ng-template #userModal let-c="close" let-d="dismiss">
  <app-modal-with-comment
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-with-comment>
</ng-template>
