import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  APIService,
  CreateFormQuestionInput,
  CreateFormQuestionMutation,
  CreateRozFormInput,
  EvaluatedRozForm,
  FormQuestionTemplate,
  ListEvaluatedRozFormsQuery,
  ListFormQuestionTemplatesQuery,
  RozForm,
  UpdateFormQuestionInput,
  UpdateFormQuestionMutation,
  UpdateRozFormInput,
} from '../../app-sync.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';
import { SelectedOptions } from '../../shared/interfaces/type-options';
import { appConstants } from '../../shared/constants/constants';
import { DocumentsService } from '../documents/documents.service';
import { documentOwner } from '../../shared/types/document-owners';
import { entities } from '../../shared/types/entities';
import { nextToken } from '../../shared/types/next-token';

@Injectable({
  providedIn: 'root',
})
export class RtcEvaluationsService {
  formId: string = '';
  formDate: string = '';
  disableLoadButton: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedOptions: BehaviorSubject<SelectedOptions> = new BehaviorSubject({
    business: '',
    type: '',
    licensePlate: '',
  });
  selectedOptionsDefined: boolean = false;
  selectedEntityExists: boolean = false;
  evaluations: EvaluatedRozForm[] = [];
  evaluationsChanged = new Subject<EvaluatedRozForm[]>();

  private rozForm: RozForm;
  private selectedEntity: entities = null;

  constructor(
    private api: APIService,
    private feedbacksService: FeedbacksService,
    private documentsService: DocumentsService
  ) {
    this.rozForm = appConstants.rozForm.initialization;
  }

  // ---------------------------------------------------
  // Métodos para las opciones que selecciona el usuario
  // ---------------------------------------------------
  /**
   * Configura las opciones seleccionadas por el usuario.
   * @param {string} business Negocio seleccionado por el usuario.
   * @param {string} type Tipo de vehículo seleccionado por el usuario.
   * @param {string} licensePlate Patente ingresada por el usuario.
   */
  setSelectedOptions(
    business: string,
    type: documentOwner,
    licensePlate: string
  ): void {
    this.selectedOptionsDefined =
      business !== '' && type !== '' && licensePlate !== '';

    this.formId = `${business}#RTC#${type}#${licensePlate}`;

    this.selectedOptions.next({
      business: business,
      type: type,
      licensePlate: licensePlate,
    });

    this.documentsService.setSelectedModel(type);
  }

  /**
   * Reinicia las opciones seleccionadas por el usuario.
   */
  resetSelectedOptions(): void {
    this.selectedEntityExists = false;
    this.selectedEntity = null;

    this.selectedOptionsDefined = false;
    this.selectedOptions.next({
      business: '',
      type: '',
      licensePlate: '',
    });
  }

  /**
   * Reinicia las opciones de formulario seleccionadas.
   */
  resetRozForm(): void {
    this.formId = '';
    this.formDate = '';
    this.rozForm = appConstants.rozForm.initialization;
  }

  /**
   * Configura el vehículo seleccionado para uso de todos los componentes.
   * @param {entities} selectedEntity Entidad
   */
  setSelectedEntity(selectedEntity: entities): void {
    this.selectedEntity = selectedEntity;
  }

  /**
   * Retorna la entidad seleccionada.
   * @return {entities}
   */
  getSelectedEntity(): entities {
    return this.selectedEntity ? { ...this.selectedEntity } : null;
  }

  // ------------------------
  // Métodos para formularios
  // ------------------------
  /**
   * Configura el formulario
   * para referencia de todos los componentes.
   * @param {RozForm} rozForm
   */
  private setRozForm(rozForm: RozForm): void {
    this.rozForm = rozForm;
    console.log('rozForm', this.rozForm);
  }

  /**
   * Actualiza el formulario.
   * @return {Promise<void>}
   */
  async refreshRozForm(): Promise<void> {
    const currentDate: Date = new Date();
    const currentMonth: number = currentDate.getMonth() + 1;
    const currentMonthFormatted: string =
      currentMonth < 10 ? '0' + currentMonth : currentMonth.toString();
    this.formDate = `${currentDate.getFullYear()}${currentMonthFormatted}`;

    this.setRozForm(
      <RozForm>await this.api.GetRozForm(this.formId, this.formDate)
    );
  }

  /**
   * Retorna el formulario.
   * @return {RozForm}
   */
  getRozForm(): RozForm {
    return { ...this.rozForm };
  }

  /**
   * Crea un formulario en la base de datos.
   * @param {CreateRozFormInput} createRozFormInput
   */
  async createRozForm(createRozFormInput: CreateRozFormInput): Promise<void> {
    await this.api
      .CreateRozForm(createRozFormInput)
      .then(() => {
        console.log('Formulario creado correctamente');
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al crear formulario RTC${createRozFormInput.formId}`
        );
      });
  }

  /**
   * Actualiza un formulario en la base de datos.
   * @param {UpdateRozFormInput} updateRozFormInput
   */
  async updateRozForm(updateRozFormInput: UpdateRozFormInput): Promise<void> {
    await this.api
      .UpdateRozForm(updateRozFormInput)
      .then((): void => {
        console.log('Formulario actualizado correctamente');
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al actualizar formulario RTC ${updateRozFormInput.formId}`
        );
      });
  }

  // --------------------------------
  // Métodos para preguntas plantilla
  // --------------------------------
  /**
   * Retorna una lista de preguntas plantilla de un tipo de formulario especificado.
   * @return {FormQuestionTemplate[]}
   */
  async getListFormQuestionTemplates(): Promise<FormQuestionTemplate[]> {
    const tempResult: ListFormQuestionTemplatesQuery =
      await this.api.ListFormQuestionTemplates(
        `${this.selectedOptions.value.business}#RTC#${this.selectedOptions.value.type}`
      );
    return <FormQuestionTemplate[]>tempResult.items;
  }

  // -------------------------------------
  // Métodos para preguntas de formularios
  // -------------------------------------
  /**
   * Crea una pregunta del formulario en la base de datos.
   * @param {CreateFormQuestionInput} createFormQuestionInput
   */
  async createRozFormQuestion(
    createFormQuestionInput: CreateFormQuestionInput
  ): Promise<void> {
    await this.api
      .CreateFormQuestion(createFormQuestionInput)
      .then((formQuestion: CreateFormQuestionMutation): void => {
        console.log(`Pregunta ${formQuestion.order} creada correctamente`);
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al crear pregunta ${createFormQuestionInput.order}`
        );
      });
  }

  /**
   * Actualiza una pregunta del formulario en la base de datos.
   * @param {UpdateFormQuestionInput} updateFormQuestionInput
   */
  async updateRozFormQuestion(
    updateFormQuestionInput: UpdateFormQuestionInput
  ): Promise<void> {
    await this.api
      .UpdateFormQuestion(updateFormQuestionInput)
      .then((formQuestion: UpdateFormQuestionMutation): void => {
        console.log(`Pregunta ${formQuestion.order} creada correctamente`);
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al actualizar pregunta ${updateFormQuestionInput.order}`
        );
      });
  }

  /**
   * Elimina el path de la imagen en S3 de una pregunta
   * del formulario en la base de datos.
   * La imagen en sí se elimina automáticamente por un ciclo de
   * limpieza del bucket en S3
   * @param {UpdateFormQuestionInput} updateFormQuestionInput
   */
  async deleteImageFromRozFormQuestion(
    updateFormQuestionInput: UpdateFormQuestionInput
  ): Promise<void> {
    await this.api
      .UpdateFormQuestion(updateFormQuestionInput)
      .then((formQuestion: UpdateFormQuestionMutation): void => {
        console.log(
          `Imagen de la pregunta ${formQuestion.order} eliminada correctamente`
        );
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al eliminar imagen ${updateFormQuestionInput.order}`
        );
      });
  }

  // --------------------------------------
  // Métodos para historiales de evaluación
  // --------------------------------------
  /**
   * Configura la lista de evaluaciones históricas para
   * referencia de todos los componentes.
   * @param {EvaluatedRozForm[]} evaluations Lista de evaluaciones.
   */
  setEvaluatedRozForms(evaluations: EvaluatedRozForm[]): void {
    this.evaluations = evaluations;
    this.evaluationsChanged.next(this.evaluations.slice());
  }

  /**
   * Actualiza la lista de evaluaciones históricas.
   */
  async refreshEvaluatedRozForms(): Promise<void> {
    let listEvaluatedRozFormsResult: ListEvaluatedRozFormsQuery =
      await this.api.ListEvaluatedRozForms(this.formId);

    let tempListEvaluatedRozForms = <EvaluatedRozForm[]>(
      listEvaluatedRozFormsResult.items
    );
    let nextToken: nextToken = listEvaluatedRozFormsResult.nextToken;

    // Es posible que la primera query no retorne todas las evaluaciones
    while (nextToken) {
      let loopListEvaluatedRozFormsResult: ListEvaluatedRozFormsQuery =
        await this.api.ListEvaluatedRozForms(
          this.formId,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListEvaluatedRozForms.push(
        ...(<EvaluatedRozForm[]>loopListEvaluatedRozFormsResult.items)
      );
      nextToken = loopListEvaluatedRozFormsResult.nextToken;
    }

    this.setEvaluatedRozForms(tempListEvaluatedRozForms.slice());
  }

  /**
   * Retorna la lista de evaluaciones
   * @return {EvaluatedRozForm[]}
   */
  getEvaluatedRozForms(): EvaluatedRozForm[] {
    return this.evaluations.slice();
  }

  // --------------------
  // Métodos para estilos
  // --------------------
  /**
   * Retorna la fuente de imagen a mostrar en el Estado de la evaluación.
   * @param {string} status Estado de la evaluación.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    let imageSrc: string = 'assets/images/icons/';

    if (status === appConstants.rozForm.codes.approved) {
      imageSrc += 'available';
    } else if (status === appConstants.rozForm.codes.reproved) {
      imageSrc += 'blocked';
    } else {
      imageSrc += 'rejected';
    }

    return imageSrc + '.svg';
  }

  /**
   * Retorna un color dependiendo del estado del formulario.
   * @return {string}
   */
  getSatusColor(): string {
    let statusColor: string;
    if (this.rozForm.status === appConstants.rozForm.codes.reproved) {
      statusColor = appConstants.colors.statuses.rejected;
    } else {
      statusColor = appConstants.colors.statuses.inProcess;
    }

    return statusColor;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el estado de la evaluación en curso.
   * @return {string} ruta a imagen.
   */
  getRozFormImageSrc(): string {
    let imageSrc: string = 'assets/images/icons/';

    if (this.rozForm.status === appConstants.rozForm.codes.reproved) {
      imageSrc += 'rejected';
    } else {
      imageSrc += 'inProcess';
    }

    return imageSrc + '.svg';
  }
}
