import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import {
  APIService,
  Semirremolque,
  CreateNotificationInput,
  DeleteSemirremolqueInput,
  UpdateSemirremolqueInput,
  UpdateSemirremolqueMutation,
  UpdateDocumentInput,
} from '../../../app-sync.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { SemirremolquesService } from '../semirremolques.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { DocumentsService } from '../../documents/documents.service';
import { UsersService } from '../../users/users.service';
import { EditionsService } from '../../../shared/services/editions.service';
import { ApiRequestsService } from '../../../shared/services/api-requests.service';
import { MasterService } from '../../master/master.service';
import { FormsService } from '../../../shared/services/forms.service';
import { ArchivesService } from '../../../shared/services/archives.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { CommonsService } from '../../../shared/services/commons.service';

@Component({
  selector: 'app-semirremolque-details',
  templateUrl: './semirremolque-details.component.html',
  styleUrls: ['./semirremolque-details.component.css'],
})
export class SemirremolqueDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('semirremolqueModal', { static: false }) semirremolqueModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('updateSemirremolqueAttributeModal', { static: false })
  updateSemirremolqueAttributeModal: TemplateRef<any> | undefined;
  @ViewChild('archiveSemirremolqueModal', { static: false })
  archiveSemirremolqueModal: TemplateRef<any> | undefined;
  currentYear: number = new Date().getFullYear();
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isBeingRejected: boolean = false;
  isGettingSemirremolque: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  modalWithComment: boolean = false;
  archiveForm: FormGroup;
  commentForm: FormGroup;
  updateAttributeForm: FormGroup;
  semirremolque: Semirremolque;
  semirremolqueNeedsApproval: boolean = false;
  semirremolqueCanBeRejected: boolean = false;
  semirremolqueIsCoupled: boolean = false;
  showBlockingMotives: boolean = false;
  coupleType: 'Tracto' = 'Tracto';
  coupleIdentification: string = '';
  coupleStatus: string = '';
  hasComment: boolean = false;
  showComment: boolean = false;

  private selectedSemirremolqueChangedSubscription: Subscription =
    new Subscription();
  private onUpdateSemirremolqueListener: ZenObservable.Subscription =
    new Subscription();
  private requestSucceededSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private apiRequestsService: ApiRequestsService,
    private documentsService: DocumentsService,
    private editionsService: EditionsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private modalsService: ModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private semirremolquesService: SemirremolquesService,
    private usersService: UsersService,
    private formsService: FormsService,
    private archivesService: ArchivesService,
    private commonsService: CommonsService
  ) {
    this.semirremolque = this.semirremolquesService.getSelectedSemirremolque();
    this.archiveForm = new FormGroup({
      motiveForArchiving: new FormControl(null),
      comment: new FormControl(null),
      documents: new FormArray([
        new FormGroup({
          documentMasterValueId: new FormControl(null),
          documentName: new FormControl(null),
          documentIssueDate: new FormControl(null),
          documentExpirationDate: new FormControl(null),
          documentFile: new FormControl(null),
          documentSourceFile: new FormControl(null),
        }),
      ]),
    });
    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(null),
    });
  }

  async ngOnInit() {
    this.selectedSemirremolqueChangedSubscription =
      this.semirremolquesService.selectedSemirremolqueChanged.subscribe(
        (semirremolque: Semirremolque) => {
          this.semirremolque = semirremolque;
          // Chequeamos si tiene acople
          this.semirremolqueIsCoupled = Boolean(
            semirremolque.semirremolqueCoupleCoupleId
          );
          if (this.semirremolqueIsCoupled) {
            this.coupleIdentification = `${
              semirremolque.couple!.coupleTractoTractoId
            }`;
            this.coupleStatus = semirremolque.couple!.status;
          } else {
            this.coupleIdentification = '';
            this.coupleStatus = '';
          }
          this.setSemirremolqueSituation();
        }
      );

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateSelectedSemirremolque();

    // Subscripción a actualizaciones del Semirremolque
    this.onUpdateSemirremolqueListener = this.api
      .OnUpdateSemirremolqueListener(
        this.semirremolquesService.getSelectedSemirremolqueFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.semirremolquesService.refreshSelectedSemirremolque();
        }
      });

    // Subscripción a consultas de API exitosas
    this.requestSucceededSubscription =
      this.apiRequestsService.requestSucceeded.subscribe(
        (requestSucceeded: boolean): void => {
          if (requestSucceeded) {
            this.onRefreshSemirremolque();
          }
        }
      );
  }

  /**
   * Actualiza el Semirremolque seleccionado.
   * @return {Promise}
   * @private
   */
  private async updateSelectedSemirremolque(): Promise<void> {
    this.isGettingSemirremolque = true;
    await this.semirremolquesService.refreshSelectedSemirremolque().then(() => {
      this.setSemirremolqueSituation();
      this.isGettingSemirremolque = false;
    });
  }

  /**
   * Ejecuta el modal de aprobación de un semirremolque y pide su
   * actualización en caso de ser aprobado.
   * @return {Promise}
   */
  async onApproveSemirremolque(): Promise<void> {
    this.initCommentForm();
    this.modalTitle = appConstants.semirremolque.modalMessages.approve.title;
    this.modalQuestion =
      appConstants.semirremolque.modalMessages.approve.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.semirremolque.sapId!,
          this.semirremolque.licensePlate
        )
      );
    this.modalMessage =
      appConstants.semirremolque.modalMessages.approve.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.semirremolqueModal
    );

    if (modalResult) {
      this.isGettingSemirremolque = true;
      await this.updateSemirremolque('approve', this.commentForm.value.comment);
    }
  }

  /**
   * Ejecuta el modal de rechazo de un semirremolque y pide su
   * actualización en caso de ser rechazado.
   * @return {Promise}
   */
  async onRejectSemirremolque(): Promise<void> {
    this.isBeingRejected = true;
    this.initCommentForm();
    this.modalTitle = appConstants.semirremolque.modalMessages.reject.title;
    this.modalQuestion =
      appConstants.semirremolque.modalMessages.reject.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.semirremolque.sapId!,
          this.semirremolque.licensePlate
        )
      );
    this.modalMessage = appConstants.semirremolque.modalMessages.reject.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.semirremolqueModal
    );

    if (modalResult) {
      this.isGettingSemirremolque = true;
      const newNotification = this.commentForm.value;
      const today: Date = new Date();
      const todayTimestamp: string = Math.floor(
        today.getTime() / 1000
      ).toString();
      const creatNotificationInput: CreateNotificationInput = {
        businessId: this.semirremolque.business,
        notificationId:
          'SEMIRREMOLQUE#' +
          this.semirremolque.semirremolqueId +
          '#' +
          todayTimestamp,
        status: 'TO_BE_SENT',
        model: 'SEMIRREMOLQUE',
        motive: 'APPROVE#REJECTED',
        semirremolqueId: this.semirremolque.semirremolqueId,
        comment: newNotification.comment,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async (): Promise<void> => {
          await this.updateSemirremolque('reject', newNotification.comment);
        });
    }

    this.isBeingRejected = false;
  }

  /**
   * Ejecuta el modal de archivado de un semirremolque y dirige
   * al componente de edición en caso de ser archivado.
   * @return {Promise}
   */
  async onArchiveSemirremolque(): Promise<void> {
    this.archivesService.setArchiving(true);
    this.initArchiveForm();
    this.modalTitle = appConstants.semirremolque.modalMessages.archive.title;
    this.modalQuestion =
      appConstants.semirremolque.modalMessages.archive.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.semirremolque.sapId!,
          this.semirremolque.licensePlate
        )
      );
    this.modalMessage =
      appConstants.semirremolque.modalMessages.archive.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.archiveSemirremolqueModal
    );

    if (modalResult) {
      console.log(' onArchiveSemirremolque @ semirremolque-details.component ');
      console.log(this.archiveForm.value);

      let documents: DocumentFromForms[] = [];
      if (this.archiveForm.get('documents') !== null) {
        documents = this.archiveForm.value.documents.slice();
      }

      // 1. Se crea el archivo.
      await this.archivesService.createArchive(
        this.semirremolque,
        this.archiveForm.value.motiveForArchiving,
        this.archiveForm.value.comment,
        documents
      );

      // 2. Dirigimos a la creación de la nueva entidad.
      this.router
        .navigate(['../new'], { relativeTo: this.route })
        .then(() => console.log('navigate to new'));
    }
  }

  /**
   * Actualiza un semirremolque.
   * @param {string} action Acción a realizar, puede ser 'approve' o 'reject'.
   * @param {string} comment Comentario de aprobación o rechazo.
   * @return {Promise}
   * @private
   */
  private async updateSemirremolque(
    action: 'approve' | 'reject',
    comment: string | null = null
  ): Promise<void> {
    this.isGettingSemirremolque = true;
    let newSemirremolqueStatus: string;
    let feedbackMessage: string = `Semirremolque ${this.commonsService.getIdentification(
      this.semirremolque.sapId!,
      this.semirremolque.licensePlate
    )} `;
    let catchFeedbackMessage: string = `Error al _ semirremolque ${this.commonsService.getIdentification(
      this.semirremolque.sapId!,
      this.semirremolque.licensePlate
    )}`;

    switch (action) {
      case 'approve':
        // Se cambia el estado del Semirremolque a "Procesando"
        newSemirremolqueStatus = `${this.semirremolque.business}_${appConstants.entity.codes.inProcess}`;
        feedbackMessage = feedbackMessage + 'aprobado. Actualizando';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'aprobar');
        break;
      case 'reject':
        // Se cambia el estado del Semirremolque a "Rechazado"
        newSemirremolqueStatus = `${this.semirremolque.business}_${appConstants.entity.codes.rejected}`;
        feedbackMessage = feedbackMessage + 'rechazado. Enviando notificación';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'rechazar');
        break;
    }

    const updateInputSemirremolque: UpdateSemirremolqueInput = {
      semirremolqueId: this.semirremolque.semirremolqueId,
      status: newSemirremolqueStatus,
      comment: comment,
    };

    await this.api
      .UpdateSemirremolque(updateInputSemirremolque)
      .then(
        async (semirremolque: UpdateSemirremolqueMutation): Promise<void> => {
          this.feedbacksService.showFeedback(feedbackMessage, 'info');

          if (action === 'approve') {
            // Se cambia el estado de los documentos con estado "Por Aprobar"
            // al estado "Procesando"
            for (let document of semirremolque.documents!.items) {
              if (
                document?.status ===
                `${semirremolque.business}_${appConstants.document.codes.toBeApproved}`
              ) {
                const updateDocumentInput: UpdateDocumentInput = {
                  documentId: document.documentId,
                  name: document.name,
                  status: `${semirremolque.business}_${appConstants.document.codes.inProcess}`,
                };
                await this.documentsService.updateDocument(updateDocumentInput);
              }
            }
          } else {
            // Se actualiza el estado del acoplamiento, si aplica.
            if (semirremolque.semirremolqueCoupleCoupleId) {
              await this.api.UpdateCouple({
                coupleId: semirremolque.semirremolqueCoupleCoupleId,
                status: `${semirremolque.business}_${appConstants.entity.codes.rejected}`,
              });
            }
          }

          // Actualizamos el Semirremolque seleccionado.
          await this.updateSelectedSemirremolque();
        }
      )
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          catchFeedbackMessage
        );
      });
  }

  /**
   * Ejecuta el modal de edición de atributos del Semirremolque.
   * @return {Promise}
   */
  async onUpdateSemirremolque(): Promise<void> {
    this.initUpdateAttributeForm();

    this.modalTitle = appConstants.semirremolque.modalMessages.edit.title;
    this.modalQuestion =
      appConstants.semirremolque.modalMessages.edit.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.semirremolque.sapId!,
          this.semirremolque.licensePlate
        )
      );
    this.modalMessage = appConstants.semirremolque.modalMessages.edit.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.updateSemirremolqueAttributeModal
    );

    if (modalResult) {
      if (this.updateAttributeForm.invalid) {
        this.feedbacksService.showFeedback(
          'Formulario inválido, no será procesada la petición.',
          'danger'
        );
        return;
      }

      const patchedSemirremolque = this.updateAttributeForm.value;
      const payload: { [key: string]: string } = {
        center: patchedSemirremolque.center,
      };

      this.apiRequestsService.updateEntity(
        this.semirremolque.business,
        this.semirremolque.__typename,
        this.commonsService.getIdentification(
          this.semirremolque.sapId!,
          this.semirremolque.licensePlate
        ),
        payload
      );
    }
  }

  /**
   * Navega al formulario de edición del Semirremolque.
   * @return {Promise}
   */
  async onEditSemirremolque(): Promise<void> {
    const editionId: string = `${this.usersService.business.value.toUpperCase()}#SEMIRREMOLQUE#${
      this.semirremolque.semirremolqueId
    }`;

    if (await this.editionsService.editionIsTaken(editionId)) {
      return;
    }

    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de eliminación de un semirremolque y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteSemirremolque(): Promise<void> {
    if (this.semirremolqueIsCoupled) {
      this.feedbacksService.showFeedback(
        `Semirremolque ${this.commonsService.getIdentification(
          this.semirremolque.sapId!,
          this.semirremolque.licensePlate
        )} está acoplado. Desacóplelo e intente de nuevo.`,
        'danger'
      );
      return;
    }

    this.modalTitle = appConstants.semirremolque.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.semirremolque.modalMessages.delete.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.semirremolque.sapId!,
          this.semirremolque.licensePlate
        )
      );
    this.modalMessage = appConstants.semirremolque.modalMessages.delete.message;
    this.modalWithComment = false;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.semirremolqueModal
    );

    if (modalResult) {
      const deleteSemirremolqueInput: DeleteSemirremolqueInput = {
        semirremolqueId: this.semirremolque.semirremolqueId,
      };

      await this.api
        .DeleteSemirremolque(deleteSemirremolqueInput)
        .then(async () => {
          this.feedbacksService.showFeedback(
            `Semirremolque ${this.commonsService.getIdentification(
              this.semirremolque.sapId!,
              this.semirremolque.licensePlate
            )} eliminado.`,
            'info'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al borrar semirremolque ${this.commonsService.getIdentification(
              this.semirremolque.sapId!,
              this.semirremolque.licensePlate
            )}`
          );
        });
      this.router
        .navigate([
          '/',
          this.semirremolque.business.toLowerCase(),
          'semirremolques',
        ])
        .then(() => console.log('navigate to semirremolques'));
    }
  }

  /**
   * Llama a refrescar el Semirremolque seleccionado.
   */
  onRefreshSemirremolque(): void {
    this.updateSelectedSemirremolque().then(() =>
      console.log('Semirremolque seleccionado actualizado.')
    );
  }

  /**
   * Colapsa o muestra el comentario del vehículo.
   */
  onViewComment(): void {
    this.showComment = !this.showComment;
  }

  /**
   * Retorna la clase que colapsa o muestra el comentario del vehículo.
   * @return {string} Clase CSS
   */
  showCommentClass(): string {
    return this.showComment ? 'show' : '';
  }

  /**
   * Inicializa el formulario de rechazo de un semirremolque.
   * @private
   */
  private initCommentForm(): void {
    // Inicialización del formulario
    this.commentForm = this.formsService.generateCommentForm(
      this.isBeingRejected
    );
  }

  /**
   * Inicializa el formulario de archivado de un semirremolque.
   * @private
   */
  private initArchiveForm(): void {
    // Inicialización del formulario
    this.archiveForm = this.formsService.generateArchiveForm([], {});
  }

  /**
   * Inicializa el formulario de actualización de
   * atributos de un Semirremolque.
   * @private
   */
  private initUpdateAttributeForm(): void {
    // Inicialización del formulario
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(this.semirremolque.center, [Validators.required]),
    });
  }

  /**
   * Indica si un semirremolque tiene documentos por vencer.
   * @return {boolean} Boolean que indica si el Semirremolque tiene documentos por vencer.
   */
  hasToExpireDocuments(): boolean {
    const docNumber: number = this.semirremolque.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un semirremolque tiene documentos vencidos.
   * @return {boolean} Boolean que indica si el Semirremolque tiene documentos vencidos.
   */
  hasExpiredDocuments(): boolean {
    const docNumber: number = this.semirremolque.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna un color dependiendo del estado del envasado.
   * @return {string}
   */
  getColor(): string {
    return this.masterService.getSatusColor(this.semirremolque.status);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del envasado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    switch (button) {
      case 'delete':
      case 'archive':
        show = this.isAdmin;
        break;
      case 'edit-center':
        show = this.isAdmin && this.semirremolque.business === 'LUBRICANTS';
        break;
      case 'approve':
      case 'reject':
        show = this.isAdmin || this.isApprover;
        break;
      case 'actions':
      case 'edit':
        show = this.isAdmin || this.isCarrier;
        break;
      default:
        break;
    }
    return show;
  }

  /**
   * Define la situación del Semirremolque: ¿necesita aprobación?, ¿puede ser rechazado?
   * ¿Está bloqueado?
   * @private
   */
  private setSemirremolqueSituation(): void {
    const semirremolqueToBeApprove: boolean =
      this.semirremolque.status ===
      `${this.semirremolque.business}_${appConstants.entity.codes.toBeApproved}`;
    const semirremolqueRejected: boolean =
      this.semirremolque.status ===
      `${this.semirremolque.business}_${appConstants.entity.codes.rejected}`;
    this.semirremolqueNeedsApproval =
      semirremolqueToBeApprove || semirremolqueRejected;
    this.semirremolqueCanBeRejected = semirremolqueToBeApprove;
    this.showBlockingMotives =
      this.semirremolque.blockingMotives !== null &&
      this.semirremolque.blockingMotives !== undefined &&
      this.semirremolque.blockingMotives.length > 0 &&
      this.semirremolque.status !==
        `${this.semirremolque.business}_${appConstants.entity.codes.available}`;
    this.hasComment = !!this.semirremolque.comment;
    this.showComment = false;
  }

  ngOnDestroy(): void {
    this.selectedSemirremolqueChangedSubscription.unsubscribe();
    this.onUpdateSemirremolqueListener.unsubscribe();
    this.requestSucceededSubscription.unsubscribe();
    console.log('semirremolque-details.component subscriptions removed.');
  }
}
