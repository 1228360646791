import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { appConstants } from 'src/app/shared/constants/constants';

import {
  APIService,
  CreateUserInput,
  CreateUserMutation,
  GetUserQuery,
  Master,
  UpdateUserInput,
  UpdateUserMutation,
  User,
} from 'src/app/app-sync.service';
import { MasterService } from 'src/app/pages/master/master.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';
import { UsersService } from '../users.service';
import { FormsService } from '../../../shared/services/forms.service';
import { environment } from '../../../../environments/environment';
import { ValidatorsService } from '../../../shared/services/validators.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css'],
})
export class UserEditComponent implements OnInit {
  editMode: boolean = false;
  isGettingUser: boolean = false;
  isGettingCenters: boolean = false;
  master: any = [];
  userForm: FormGroup;
  userId: string = '';
  accessToBusiness: boolean = false;
  groupWithCenterAccessSelected: boolean = false;
  validUserGroups: any = [];
  selectAll: boolean = false;
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';

  get businessesControl() {
    return (<FormArray>this.userForm.get('hasAccessTo')).controls;
  }

  get centersControl() {
    return (<FormArray>this.userForm.get('centers')).controls;
  }

  get userControl() {
    return this.userForm;
  }

  constructor(
    private api: APIService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private formsService: FormsService,
    private validatorsService: ValidatorsService
  ) {
    const business: string = this.usersService.business.value.toUpperCase();
    this.userForm = new FormGroup({
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      rut: new FormControl(null),
      phone: new FormControl(null),
      email: new FormControl(null),
      authGroup: new FormControl(null),
      company: new FormControl(null),
      hasAccessTo: this.formsService.businessesFormArrayInitialization([]),
      selectAll: new FormControl(null),
      centers: this.formsService.centersFormArrayInitialization({
        [`${business}`]: [],
      }),
    });
  }

  ngOnInit() {
    this.isGettingUser = true;
    this.master = this.masterService.getMaster();
    this.route.params.subscribe(async (params: Params) => {
      // Si la ruta contiene un ID de usuario
      // estamos en modo edición
      this.editMode = params['id'] != null;
      this.userId = decodeURIComponent(params['id']);

      this.initUserForm();
      this.isGettingUser = false;
    });
  }

  /* ------------------------------------------- */
  /* - Métodos para la creación del Formulario - */
  /* ------------------------------------------- */
  /**
   * Inicializa el formulario para crear o
   * editar un usuario.
   * @private
   */
  private initUserForm(): void {
    // Inicialización del Formulario
    const activeUserBusiness: string =
      this.usersService.business.value.toUpperCase();
    // Por default, los campos estarán vacíos.
    let initialUser: User = appConstants.user.initialization;
    let userBusinesses: FormArray =
      this.formsService.businessesFormArrayInitialization([]);
    let userCenters: FormArray =
      this.formsService.centersFormArrayInitialization({
        [`${activeUserBusiness}`]: [],
      });

    if (this.editMode) {
      // En modo de Edición: cargamos los valores de los campos
      // según el usuario escogido.
      initialUser = this.usersService.getSelectedUser();
      const business: string = initialUser.business;

      const businessesWithAccessTo: string[] = initialUser.hasAccessTo?.split(
        ','
      ) || [this.usersService.business.value.toUpperCase()];
      userBusinesses = this.formsService.businessesFormArrayInitialization(
        businessesWithAccessTo
      );

      userCenters = this.formsService.centersFormArrayInitialization(
        JSON.parse(initialUser.centers || `{"${business}": []}`)
      );

      this.setValidUserGroups(initialUser.company);
      this.groupWithCenterAccessSelected =
        initialUser.authGroup.endsWith('_VIEWERS') ||
        initialUser.authGroup.endsWith('_APPROVERS');
      this.accessToBusiness =
        initialUser.authGroup.endsWith('_ADMINS') ||
        initialUser.authGroup.endsWith('_VIEWERS') ||
        initialUser.authGroup.endsWith('_APPROVERS');
    } else {
      initialUser.company = 'COPEC';
      this.setValidUserGroups('COPEC');
    }

    this.userForm = new FormGroup({
      firstName: new FormControl(
        { value: initialUser.firstName, disabled: this.editMode },
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(appConstants.regex.name),
        ]
      ),
      lastName: new FormControl(
        { value: initialUser.lastName, disabled: this.editMode },
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(appConstants.regex.name),
        ]
      ),
      rut: new FormControl(
        { value: initialUser.rut, disabled: this.editMode },
        [Validators.required, Validators.pattern(appConstants.regex.rut)]
      ),
      phone: new FormControl({ value: initialUser.phone, disabled: false }, [
        Validators.required,
        Validators.pattern(appConstants.regex.phone),
      ]),
      email: new FormControl(
        { value: initialUser.email, disabled: this.editMode },
        [Validators.required, Validators.email],
        [this.validatorsService.emailInUseValidator()]
      ),
      authGroup: new FormControl(
        { value: initialUser.authGroup, disabled: false },
        Validators.required
      ),
      company: new FormControl(
        { value: initialUser.company, disabled: this.editMode },
        Validators.required
      ),
      hasAccessTo: userBusinesses,
      selectAll: new FormControl<boolean>(false, Validators.required),
      centers: userCenters,
    });
  }

  /* --------------------------------------- */
  /* - Métodos que modifican el Formulario - */
  /* --------------------------------------- */
  /**
   * Resetea el valor de la variable authGroup en el formulario
   * y remueve el control de centro cuando se cambia el valor de company.
   * También resetea los valores de acceso a negocios.
   */
  onCompanyChange(): void {
    // Planteamos la lista de posibles grupos según la compañía
    this.setValidUserGroups(this.userForm.get('company')?.value);
    // Reseteamos el valor del grupo del usuario
    this.userForm.get('authGroup')?.reset('');
    // Reseteamos todos los controles de acceso a negocios menos el default
    this.resetAccessBusinessControls();
    // Reseteamos todos los controles de acceso a negocios menos el default
    this.resetAccessCentersControls();
    // Reseteamos el control de Seleccionar Todos
    this.resetSelectAll();
  }

  /**
   * Verifica si el grupo del usuario a crear es Visualizador y agrega o
   * remueve el control de Centro del formulario.
   * También resetea los valores de acceso a negocios.
   */
  onAuthGroupChange(): void {
    this.resetAccessBusinessControls();
    this.resetAccessCentersControls();
    this.resetSelectAll();

    const authGroup: string = this.userForm.get('authGroup')?.value || '';
    this.groupWithCenterAccessSelected =
      authGroup.endsWith('_VIEWERS') || authGroup.endsWith('_APPROVERS');
    this.accessToBusiness =
      authGroup.endsWith('_ADMINS') ||
      authGroup.endsWith('_VIEWERS') ||
      authGroup.endsWith('_APPROVERS');
  }

  /**
   * Agrega o remueve centros del control "centers" del formulario.
   * @param {number} controlIndex Índice del control de negocio cambiado.
   * @return {Promise<void>}
   */
  async onBusinessChange(controlIndex: number): Promise<void> {
    const businessName = this.businessesControl
      .at(controlIndex)
      ?.get('businessName')?.value;
    const hasAccess = this.businessesControl
      .at(controlIndex)
      ?.get('hasAccess')?.value;

    if (hasAccess && this.groupWithCenterAccessSelected) {
      this.isGettingCenters = true;
      // Reseteamos el Seleccionar Todos
      this.resetSelectAll();
      // Agregamos los centros asociados al negocio.
      let centersList: string[] =
        await this.masterService.getUserCentersByBusiness(businessName);

      centersList.forEach((center: string) => {
        (<FormArray>this.userForm.get('centers')).push(
          new FormGroup({
            businessName: new FormControl<string | null>(
              businessName,
              Validators.required
            ),
            centerName: new FormControl<string | null>(
              center,
              Validators.required
            ),
            hasAccess: new FormControl<boolean>(false, Validators.required),
          })
        );
      });
      // Actualizamos el maestro local
      this.master = this.masterService.getMaster();
      this.isGettingCenters = false;
    } else if (!hasAccess && this.groupWithCenterAccessSelected) {
      // Removemos los controles con centros asociados al negocio.
      (<FormArray>this.userForm.get('centers')).controls =
        this.centersControl.filter(
          (control: AbstractControl<any, any>): boolean =>
            control.get('businessName')?.value !== businessName
        );
      // Removemos los valores con centros asociados al negocio.
      (<FormArray>this.userForm.get('centers')).setValue(
        this.userForm.value.centers.filter(
          (value: any): boolean => value.businessName !== businessName
        )
      );
    }
  }

  /**
   * Configura los grupos de usuario válidos según la empresa escogida.
   * @param {string} valueId Valor del campo company del User
   */
  setValidUserGroups(valueId: string): void {
    this.accessToBusiness = false;
    this.groupWithCenterAccessSelected = false;

    if (valueId === 'COPEC') {
      this.validUserGroups = this.master['USERS#GROUPS'].filter(
        (item: Master) => {
          return (
            item.valueId.endsWith('_ADMINS') ||
            item.valueId.endsWith('_VIEWERS') ||
            item.valueId.endsWith('_APPROVERS')
          );
        }
      );
    } else if (valueId.endsWith('_95841000-K')) {
      this.validUserGroups = this.master['USERS#GROUPS'].filter(
        (item: Master) => {
          return item.valueId.endsWith('_RTC');
        }
      );
    } else {
      this.validUserGroups = this.master['USERS#GROUPS'].filter(
        (item: Master) => {
          return (
            item.valueId.endsWith('_CARRIERS') ||
            item.valueId.endsWith('_DRIVERS')
          );
        }
      );
    }
  }

  /**
   * Resetea los controles de acceso a negocios excepto por el default.
   */
  resetAccessBusinessControls(): void {
    for (let control of (<FormArray>this.userForm.get('hasAccessTo'))
      .controls) {
      if (
        control.get('businessName')?.value !==
        this.usersService.business.value.toUpperCase()
      ) {
        control.get('hasAccess')?.reset(false);
      }
    }
  }

  /**
   * Resetea los controles de acceso a centros de distribución.
   */
  resetAccessCentersControls(): void {
    // Dejamos solo los centros por default.
    (<FormArray>this.userForm.get('centers')).controls =
      this.centersControl.filter(
        (control: AbstractControl<any, any>) =>
          control.get('businessName')?.value ===
          this.usersService.business.value.toUpperCase()
      );
    for (let control of (<FormArray>this.userForm.get('centers')).controls) {
      control.get('hasAccess')?.reset(false);
    }
  }

  /**
   * Cambien valor de todos los checkboxes de centros a la vez.
   */
  selectAllModels(): void {
    this.selectAll = !this.selectAll;
    for (let control of (<FormArray>this.userForm.get('centers')).controls) {
      control.get('hasAccess')!.setValue(this.selectAll);
    }
    this.userForm.get('selectAll')?.setValue(this.selectAll);
  }

  /**
   * Resetea a falso el valor de la variable selectAll
   */
  resetSelectAll(): void {
    this.selectAll = false;
    this.userForm.get('selectAll')?.reset(false);
  }

  /* ---------------------------------------- */
  /* - Métodos de validación del Formulario - */
  /* ---------------------------------------- */
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /* ------------------------------- */
  /* - Métodos asociados a botones - */
  /* ------------------------------- */
  /**
   * Crea o edita un usuario.
   */
  async onSubmit() {
    let newUser = this.userForm.value;
    const businessAccessList = this.userForm.value.hasAccessTo.slice();
    const centersAccessList = this.userForm.value.centers.slice();

    delete newUser.hasAccessTo;
    delete newUser.centers;
    delete newUser.selectAll;

    // El usuario siempre tendrá acceso al negocio en el que fue creado.
    let hasAccessToBusinesses: string =
      this.usersService.business.value.toUpperCase();
    // Cualquier otro negocio proveniente del formulario se agrega a la lista.
    businessAccessList.forEach((business: any) => {
      if (business.hasAccess) {
        hasAccessToBusinesses += ',' + business.businessName;
      }
    });

    let hasAccessToCenters: { [key: string]: string[] } = {};
    centersAccessList.forEach((center: any): void => {
      if (center.hasAccess) {
        hasAccessToCenters[center.businessName] = hasAccessToCenters[
          center.businessName
        ]
          ? hasAccessToCenters[center.businessName]
          : [];
        hasAccessToCenters[center.businessName].push(center.centerName);
      }
    });

    if (this.editMode) {
      // Nota: En el modo edición el ID fue fijado al momento de iniciar el formulario.
      this.feedbacksService.showFeedback(
        'Actualizando usuario: ' + this.userId,
        'info'
      );
      const updateUserInput: UpdateUserInput = {
        ...newUser,
        hasAccessTo: hasAccessToBusinesses,
        centers: JSON.stringify(hasAccessToCenters),
        userId: this.userId,
      };
      this.api
        .UpdateUser(updateUserInput)
        .then((user: UpdateUserMutation) => {
          this.feedbacksService.showFeedback(
            'Usuario ' + user.email + ' actualizado correctamente',
            'success'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar usuario ${this.usersService
              .getSelectedUser()
              .email.toLowerCase()}`
          );
        });
    } else {
      // En modo creación o archivo:
      // 1.  Se verifica si no existe un usuario con el mismo correo.
      const userExists: boolean = await this.usersService.checkUserExistence(
        newUser.email.toLowerCase()
      );
      if (userExists) {
        this.feedbacksService.showFeedback(
          `Usuario con correo ${newUser.email.toLowerCase()} ya existe.`,
          'danger'
        );
        return;
      }

      this.feedbacksService.showFeedback(
        `Creando usuario: ${newUser.email.toLowerCase()}`,
        'info'
      );

      const createUserInput: CreateUserInput = {
        ...newUser,
        hasAccessTo: hasAccessToBusinesses,
        centers: JSON.stringify(hasAccessToCenters),
        userId: newUser.email.toLowerCase(),
        business: this.usersService.business.value.toUpperCase(),
        status: `${this.usersService.business.value.toUpperCase()}_${
          appConstants.user.codes.active
        }`,
      };

      this.api
        .CreateUser(createUserInput)
        .then((user: CreateUserMutation) => {
          this.feedbacksService.showFeedback(
            `Usuario ${user.email} creado correctamente`,
            'success'
          );
        })
        .catch((response: any): void => {
          this.userCreationErrorHandler(response, newUser);
        });
    }

    // Regresamos al detalle del usuario.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /**
   * Maneja errores de creación de usuarios.
   * @param {any} response Respuesta recibida en el catch.
   * @param {User} newUser Usuario que se intentó crear.
   */
  private userCreationErrorHandler(response: any, newUser: User): void {
    for (let error of response.errors) {
      if (error.errorType === 'DynamoDB:ConditionalCheckFailedException') {
        // Chequeamos si el usuario ya existía.
        this.api
          .GetUser(newUser.email.toLowerCase())
          .then((user: GetUserQuery) => {
            if (user) {
              this.feedbacksService.showFeedback(
                `Error al crear usuario ${newUser.email.toLowerCase()}: el usuario ya existe.`,
                'danger'
              );
            } else {
              this.feedbacksService.showFeedback(
                `Error al crear usuario ${newUser.email.toLowerCase()}: ${
                  error.message
                }`,
                'danger'
              );
            }
          });
      } else {
        this.feedbacksService.showFeedback(
          `Error al crear usuario ${newUser.email.toLowerCase()}: ${
            error.message
          }`,
          'danger'
        );
      }
    }
  }

  /**
   * Navega al detalle del usuario.
   */
  onCancel(): void {
    // Regresamos al detalle del usuario.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.userForm);
  }
}
