import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthenticatorService } from '@aws-amplify/ui-angular';

import {
  User,
  APIService,
  ListMailPreferencesQuery,
  MailPreference,
} from '../app-sync.service';
import { CognitoService } from '../auth/cognito.service';
import { UsersService } from '../pages/users/users.service';
import { ModalsService } from '../shared/modals/modals.service';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FeedbacksService } from '../shared/feedbacks/feedbacks.service';
import { OriginalMailPreferenceState } from '../shared/interfaces/originalmail-preferencestate';
import { FormsService } from '../shared/services/forms.service';
import { appConstants } from '../shared/constants/constants';
import { ValidatorsService } from '../shared/services/validators.service';
import { HeaderButtonsList } from '../shared/interfaces/header-buttons-list';
import { HeaderService } from './header.service';
import { ApiRequestsService } from '../shared/services/api-requests.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('headerModal', { static: false }) headerModal:
    | TemplateRef<any>
    | undefined;
  activeUserName: string = '';
  businessDefined: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isDriver: boolean = false;
  isViewer: boolean = false;
  isRTC: boolean = false;
  activeUserInHome: boolean = false;
  //Para validar descripción de los roles:
  // isAdmin = Administrador(a), isCarrier = Transportista, isDriver = Conductor(a), etc...
  roleDescription: string = '';

  buttonsState: HeaderButtonsList;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  excelForm: FormGroup;
  selectAll: boolean = false;
  business: string = '';
  status: boolean = false;
  isDropdownOpen: boolean = false;
  isSidebarOpen: boolean = false;
  mailPreferences: MailPreference[] = [];
  originalStates: OriginalMailPreferenceState[] = [];
  actualizandoPreferencias: boolean = true;
  userId: string = '';
  isGettingMailPreferences: boolean = false;
  isUpdatingPreferences: boolean = false;

  private businessSubscription: Subscription = new Subscription();
  private userGroupSubscription: Subscription = new Subscription();
  private activeUserChangedSubscription: Subscription = new Subscription();
  private activeUserInHomeSubscription: Subscription = new Subscription();
  private buttonsStateSubscription: Subscription = new Subscription();

  get modelsControl() {
    return (<FormArray>this.excelForm.get('models')).controls;
  }

  constructor(
    private router: Router,
    private usersService: UsersService,
    private authenticatorService: AuthenticatorService,
    private modalsService: ModalsService,
    private formsService: FormsService,
    private validatorsService: ValidatorsService,
    private cognitoService: CognitoService,
    private feedbacksService: FeedbacksService,
    private api: APIService,
    private headerService: HeaderService,
    private apiRequestsService: ApiRequestsService
  ) {
    this.excelForm = new FormGroup({
      selectAll: new FormControl(null),
      models: this.formsService.modelsFormArrayInitialization([]),
    });
    this.buttonsState = this.headerService.getButtonsState();
  }

  ngOnInit(): void {
    const user: User = this.usersService.getActiveUser();
    this.activeUserName = user.firstName + ' ' + user.lastName;
    this.updateActiveUserRole();

    this.businessSubscription = this.usersService.business.subscribe(
      (business: string): void => {
        this.businessDefined = business !== '';
        this.business = business;
      }
    );

    this.userGroupSubscription = this.usersService.authGroup.subscribe(
      (): void => {
        this.updateActiveUserRole();
      }
    );
    this.activeUserChangedSubscription =
      this.usersService.activeUserChanged.subscribe((user: User): void => {
        this.activeUserName = user.firstName + ' ' + user.lastName;
        this.userId = user.userId;
        this.updateActiveUserRole();
      });
    this.activeUserInHomeSubscription = this.usersService.inHomePage.subscribe(
      (value: boolean) => {
        this.activeUserInHome = value;
      }
    );
    this.buttonsStateSubscription =
      this.headerService.selectedButtonChanged.subscribe(
        (buttonsState: HeaderButtonsList): void => {
          this.buttonsState = buttonsState;
        }
      );
  }

  /**
   * Actualiza el rol del usuario activo
   */
  private updateActiveUserRole(): void {
    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;
    this.isDriver = this.usersService.isDriver;
    this.isViewer = this.usersService.isViewer;
    this.isRTC = this.usersService.isRTC;

    switch (true) {
      case this.isAdmin:
        this.roleDescription = 'Administrador(a)';
        break;
      case this.isApprover:
        this.roleDescription = 'Aprobador(a)';
        break;
      case this.isCarrier:
        this.roleDescription = 'Transportista';
        break;
      case this.isDriver:
        this.roleDescription = 'Conductor(a)';
        break;
      case this.isViewer:
        this.roleDescription = 'Visualizador(a)';
        break;
      case this.isRTC:
        this.roleDescription = 'Evaluador(a)';
        break;
      default:
        this.roleDescription = 'No disponible';
        break;
    }
  }

  /**
   * Actualiza el estado del dropdown.
   */
  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  /**
   * Actualiza el estado del sidebar.
   */
  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  /**
   * Abre el sidebar y maneja la opción seleccionada
   * por el usuario.
   * @param event
   */
  selectOption(event: any): void {
    const option = event.target.textContent;
    if (option === 'Notificaciones') {
      // Obtenemos las preferencias del usuario.
      this.isGettingMailPreferences = true;
      this.llamarListMailPreferences(this.userId).then(() => {
        this.isGettingMailPreferences = false;
      });
    }
    this.isDropdownOpen = false; // Cerrar el menú desplegable después de seleccionar una opción
  }

  /**
   * Llama a la API para obtener la lista de preferencias de correo para el usuario especificado.
   * Asigna los elementos filtrados a `this.mailPreferences` y los inicializa como la copia de seguridad
   * de los estados originales en `this.originalStates`.
   *
   * @param userId El ID del usuario para el que se solicitan las preferencias de correo.
   */
  async llamarListMailPreferences(userId: string) {
    await this.api
      .ListMailPreferences(userId)
      .then((preferences: ListMailPreferencesQuery) => {
        this.mailPreferences = <MailPreference[]>preferences.items;
        // Inicializar la copia de seguridad de los estados originales
        this.originalStates = this.mailPreferences.map((preference) => ({
          mailId: preference.mailId,
          receive: preference.receive,
        }));
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error obteniendo preferencias de correo`
        );
      });
  }

  /**
   * Cambia el valor de una preferencia entre verdadero y falso.
   * @param {MailPreference} preference
   */
  toggleReceive(preference: MailPreference): void {
    const originalPreference: OriginalMailPreferenceState | undefined =
      this.originalStates.find(
        (state: OriginalMailPreferenceState): boolean =>
          state.mailId === preference.mailId
      );
    this.actualizandoPreferencias = !!(
      originalPreference && originalPreference.receive !== preference.receive
    );

    // Aquí puedes mantener la lógica existente para cambiar el estado de la preferencia
    preference.receive = !preference.receive;
  }

  /**
   * Actualiza las preferencias de correo para el usuario.
   * Recorre la lista de preferencias de correo y envía una solicitud de actualización
   * con el nuevo estado de cada preferencia al servidor.
   * Muestra un mensaje de retroalimentación en la interfaz de usuario para indicar
   * que las preferencias se han actualizado correctamente.
   * Si ocurre algún error durante la actualización, se maneja y se registra en la consola.
   */
  async actualizarPreferenciasDeCorreo(): Promise<void> {
    this.isUpdatingPreferences = true;
    for (const preference of this.mailPreferences) {
      // Envía una solicitud de actualización con el nuevo estado
      const originalPreference: OriginalMailPreferenceState =
        this.originalStates.find(
          (state: OriginalMailPreferenceState): boolean =>
            state.mailId === preference.mailId
        )!;

      if (preference.receive !== originalPreference.receive) {
        await this.api
          .UpdateMailPreference({
            userId: preference.userId,
            mailId: preference.mailId,
            receive: preference.receive,
          })
          .then(() => {
            console.log(`Preferencias ${preference.mailId} actualizada.`);
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error actualizando preferencia ${preference.mailId}`
            );
          });
      }
    }

    this.isUpdatingPreferences = false;
    this.feedbacksService.showFeedback(
      `Preferencias actualizadas correctamente`,
      'success'
    );

    this.toggleSidebar();
    this.actualizandoPreferencias = true;
  }

  /**
   * Cierra el dropdown si se hace click fuera de éste.
   * @param event
   */
  closeDropdownOutside(event: MouseEvent): void {
    if (this.isDropdownOpen) {
      const dropdown: HTMLElement = document.querySelector(
        '.dropdown-menu'
      ) as HTMLElement;
      if (dropdown && !dropdown.contains(event.target as Node)) {
        this.isDropdownOpen = false;
      }
    }
  }

  /**
   * Actualiza el valor de la variable isDropdownOpen, dependiendo del
   * clic que haga el usuario.
   * @param {MouseEvent} event
   */
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const isClickedOnDropdownToggle = (event.target as HTMLElement).closest(
      '.dropdown-toggle'
    );
    const isClickedInsideDropdown = (event.target as HTMLElement).closest(
      '.dropdown-menu'
    );

    if (
      !isClickedOnDropdownToggle &&
      !isClickedInsideDropdown &&
      this.isDropdownOpen
    ) {
      this.isDropdownOpen = false;
    }
  }

  /**
   * Ejecuta el modal de descarga de un Excel.
   * @return {Promise}
   */
  async onDownloadExcel(): Promise<void> {
    this.resetSelectAll();
    this.initExcelForm();

    this.modalTitle = appConstants.others.modalMessages.downloadExcel.title;
    this.modalQuestion =
      appConstants.others.modalMessages.downloadExcel.question;
    this.modalMessage = appConstants.others.modalMessages.downloadExcel.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.headerModal
    );

    if (modalResult) {
      console.log(' onDownloadExcel @ header.component ');
      console.log(this.excelForm.value);

      const modelsList: string[] = [];

      // Creamos una lista con los modelos requeridos por el usuario.
      this.excelForm.value.models.forEach((model: any) => {
        if (model.wanted) {
          modelsList.push(model.modelName);
        }
      });

      this.apiRequestsService.downloadExcelFile(
        this.business,
        modelsList.join(',')
      );
    }
  }

  /**
   * Inicializa el formulario para descarga de archivos Excel.
   * @private
   */

  private initExcelForm(): void {
    let modelNames: string[] = [];

    switch (this.business) {
      case 'nocore':
        modelNames = ['vehicle'];
        break;

      case 'foreign':
        modelNames = ['tracto', 'tanque'];
        break;

      default:
        modelNames = [
          'envasado',
          'cisterna',
          'tracto',
          'tanque',
          'semirremolque',
        ];
        break;
    }
    modelNames.push('driver');

    this.excelForm = new FormGroup(
      {
        selectAll: new FormControl<boolean>(false, Validators.required),
        models: this.formsService.modelsFormArrayInitialization(modelNames),
      },
      this.validatorsService.atLeastOneTrueValidator()
    );
  }

  /**
   * Cambien valor de todos los checkboxes a la vez.
   */
  selectAllModels(): void {
    this.selectAll = !this.selectAll;
    for (let control of (<FormArray>this.excelForm.get('models')).controls) {
      control.get('wanted')!.setValue(this.selectAll);
    }
    this.excelForm.get('selectAll')?.setValue(this.selectAll);
  }

  /**
   * Resetea a falso el valor de la variable selectAll
   */
  resetSelectAll(): void {
    this.selectAll = false;
    this.excelForm.get('selectAll')?.setValue(this.selectAll);
  }

  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /**
   * Retorna la clase de la pestaña.
   * @param {string} linkName Pestaña a considerar.
   * @return {string} Clase CSS.
   */
  getClass(linkName: keyof HeaderButtonsList): string {
    return this.buttonsState[linkName]
      ? 'active custom-a-active'
      : 'custom-a-inactive';
  }

  /**
   * Maneja el arreglo que contiene información del botón activo
   * y realiza las navegaciones.
   * @param {string} linkName
   */
  onSelected(linkName: keyof HeaderButtonsList): void {
    let path: string[] = this.headerService.setSelectedButton(linkName);

    this.router
      .navigate(path)
      .then(() => console.log(`navigate to /${path.slice(1).join('/')}`));
  }

  /**
   * Limpia los datos del usuario de localStorage,
   * cierra la sesión del usuario y navega a la raíz.
   */
  onLogOut(): void {
    this.usersService.business.next('');
    this.cognitoService.signOut().then(() => console.log('user sign out'));
    this.router.navigate(['/']).then(() => console.log('navigate to root'));
  }

  /**
   * Determina, con base al rol del usuario, el negocio y tomando en cuenta si
   * este está autenticado, cuáles botones mostrar.
   * @param {string} button Nombre del botón.
   * @return {Boolean}
   */
  showButton(button: string): boolean {
    let show: boolean;
    // Negocio del usuario
    const isNoCore: boolean = this.usersService.business.value === 'nocore';
    const isForeign: boolean = this.usersService.business.value === 'foreign';
    const isLubricants: boolean =
      this.usersService.business.value === 'lubricants';
    // Negocio del usuario definido y usuario Autenticado
    const authenticatedAndBusinessDefined: boolean =
      this.authenticatorService.user && this.businessDefined;
    // Es Administrador, Aprobador, Transportista o Visualizador
    const isAdminApproverCarrierOrViewer: boolean =
      this.isAdmin || this.isApprover || this.isCarrier || this.isViewer;
    // Negocio del usuario definido, usuario Autenticado y no está en el Home
    const authenticatedBusinessDefinedAndNotInHome: boolean =
      authenticatedAndBusinessDefined && !this.activeUserInHome;
    // es RTC y Negocio del usuario definido, usuario Autenticado y no está en el Home
    const rtcAuthenticatedBusinessDefinedAndNotInHome: boolean =
      authenticatedBusinessDefinedAndNotInHome && this.isRTC;
    switch (button) {
      case 'vehicles':
        show =
          authenticatedBusinessDefinedAndNotInHome &&
          isAdminApproverCarrierOrViewer &&
          isNoCore;
        break;
      case 'tractos':
      case 'tanques':
      case 'couplings':
        show =
          authenticatedBusinessDefinedAndNotInHome &&
          isAdminApproverCarrierOrViewer &&
          (isLubricants || isForeign);
        break;
      case 'envasados':
      case 'cisternas':
      case 'semirremolques':
        show =
          authenticatedBusinessDefinedAndNotInHome &&
          isAdminApproverCarrierOrViewer &&
          isLubricants;
        break;
      case 'drivers':
        show =
          authenticatedBusinessDefinedAndNotInHome &&
          (isAdminApproverCarrierOrViewer || this.isDriver);
        break;
      case 'users':
      case 'settings':
      case 'master':
        show = authenticatedBusinessDefinedAndNotInHome && this.isAdmin;
        break;
      case 'user-name':
      case 'logout':
        show = Boolean(this.authenticatorService.user);
        break;
      case 'excel':
        show =
          authenticatedBusinessDefinedAndNotInHome &&
          isAdminApproverCarrierOrViewer;
        break;
      case 'rtc':
        show = rtcAuthenticatedBusinessDefinedAndNotInHome;
        break;
      case 'rtc-history':
        show =
          rtcAuthenticatedBusinessDefinedAndNotInHome ||
          (this.isAdmin && isLubricants && !this.activeUserInHome);

        break;
      default:
        show = authenticatedAndBusinessDefined;
        break;
    }
    return show;
  }

  ngOnDestroy(): void {
    this.businessSubscription.unsubscribe();
    this.userGroupSubscription.unsubscribe();
    this.activeUserChangedSubscription.unsubscribe();
    this.activeUserInHomeSubscription.unsubscribe();
    this.buttonsStateSubscription.unsubscribe();
    console.log('header.component subscriptions removed.');
    document.body.removeEventListener(
      'click',
      this.closeDropdownOutside.bind(this)
    );
  }
}
