import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MasterService } from '../master/master.service';
import { environment } from '../../../environments/environment';
import { appConstants } from '../../shared/constants/constants';
import {
  LocalMasterToDisplay,
  TypeOptions,
} from '../../shared/interfaces/type-options';
import { RtcEvaluationsService } from './rtc-evaluations.service';
import {
  APIService,
  Cisterna,
  Envasado,
  Master,
  Semirremolque,
  Tanque,
  Tracto,
  User,
  Vehicle,
} from '../../app-sync.service';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { Subscription } from 'rxjs';
import { UsersService } from '../users/users.service';
import { CognitoService } from 'src/app/auth/cognito.service';
import { entities } from '../../shared/types/entities';

@Component({
  selector: 'app-formulario-rtc',
  templateUrl: './rtc-evaluations.component.html',
  styleUrls: ['./rtc-evaluations.component.css'],
})
export class RtcEvaluationsComponent implements OnInit, OnDestroy {
  master: any = {};
  masterDisplayMap: any = {};
  optionsForm: FormGroup;
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  typeOptions: TypeOptions = {
    LUBRICANTS: [
      {
        valueId: 'ENVASADO',
        valueToDisplay: 'Envasado Rígido',
      },
      {
        valueId: 'CISTERNA',
        valueToDisplay: 'Cisterna',
      },
      {
        valueId: 'TRACTO',
        valueToDisplay: 'Tracto',
      },
      {
        valueId: 'TANQUE',
        valueToDisplay: 'Tanque',
      },
      {
        valueId: 'SEMIRREMOLQUE',
        valueToDisplay: 'Semirremolque',
      },
    ],
    NOCORE: [
      {
        valueId: 'VEHICLE',
        valueToDisplay: 'Vehículo',
      },
    ],
  };
  businessSelected: keyof TypeOptions = 'LUBRICANTS';
  typesForBusinessSelected: LocalMasterToDisplay[] = [];
  isGettingEntity: boolean = false;
  currentYear: number = new Date().getFullYear();
  disableLoadButton: boolean = false;
  selectedEntity: entities = null;

  private optionsSelectedSubscription: Subscription = new Subscription();
  private disableLoadButtonSubscription: Subscription = new Subscription();
  private businessSubscription: Subscription = new Subscription();
  userBusiness: string = '';
  userRole: string = '';
  user: User;
  isRTC: boolean = false;

  constructor(
    private masterService: MasterService,
    private rtcEvaluationsService: RtcEvaluationsService,
    private api: APIService,
    private feedbacksService: FeedbacksService,
    private usersService: UsersService,
    private cognitoService: CognitoService
  ) {
    this.optionsForm = new FormGroup({
      business: new FormControl(null),
      type: new FormControl(null),
      licensePlate: new FormControl(null),
    });
    this.user = this.usersService.getActiveUser();
    this.userRole = this.user.authGroup;
  }

  async ngOnInit(): Promise<void> {
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.initOptionsForm();
    this.isRTC = this.usersService.isRTC;
    let data = await this.cognitoService.getUser();
    this.userRole = data.attributes['custom:role'].split('"')[1];
    this.businessSubscription = this.usersService.business.subscribe(
      (business: string): void => {
        this.userBusiness = business.toLocaleUpperCase();
      }
    );
    this.alloyBusiness(this.userBusiness);
    // Subscripción para actualizar formulario si cambian las opciones seleccionadas
    this.optionsSelectedSubscription =
      this.rtcEvaluationsService.selectedOptions.subscribe(
        async (): Promise<void> => {
          if (
            !this.rtcEvaluationsService.selectedOptionsDefined &&
            !this.rtcEvaluationsService.selectedEntityExists
          ) {
            this.initOptionsForm();
          }
        }
      );
    // Suscripción para deshabilitar el botón de carga mientras otro componente está cargando
    this.disableLoadButtonSubscription =
      this.rtcEvaluationsService.disableLoadButton.subscribe(
        (disableLoadButton: boolean): void => {
          this.disableLoadButton = disableLoadButton;
        }
      );
  }

  /**
   * Inicializa el formulario para que el usuario
   * escoja las opciones del vehículo a evaluar.
   */
  private initOptionsForm(): void {
    this.optionsForm = new FormGroup({
      business: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      licensePlate: new FormControl(null, [
        Validators.required,
        Validators.pattern(appConstants.regex.licensePlate),
      ]),
    });
  }

  /**
   * Registra en el servicio de RTC
   * las opciones escogidas por el usuario.
   * @protected
   */
  async onSubmit(): Promise<void> {
    this.rtcEvaluationsService.disableLoadButton.next(true);
    this.isGettingEntity = true;

    // ¿Existe el vehículo en la BD?
    if (await this.getEntity()) {
      // Configuramos el vehículo seleccionado en el servicio.
      this.rtcEvaluationsService.selectedEntityExists = true;
      this.rtcEvaluationsService.setSelectedEntity(this.selectedEntity);
      this.rtcEvaluationsService.setSelectedOptions(
        this.optionsForm.get('business')!.value,
        this.optionsForm.get('type')!.value,
        this.optionsForm.get('licensePlate')!.value.toUpperCase()
      );
    } else {
      this.selectedEntity = null;
      this.feedbacksService.showFeedback(
        `Patente ${this.optionsForm
          .get('licensePlate')!
          .value.toUpperCase()} no encontrada`,
        'danger'
      );
      this.rtcEvaluationsService.resetSelectedOptions();
      this.rtcEvaluationsService.resetRozForm();
      this.rtcEvaluationsService.disableLoadButton.next(false);
    }
    this.isGettingEntity = false;
  }

  /**
   * Configura la entidad seleccionada y retorna un booleano
   * indicando si la entidad existe o no.
   * @return {boolean} ¿Existe el vehículo solicitado en la BD?
   */
  private async getEntity(): Promise<boolean> {
    const licensePlate: string = this.optionsForm
      .get('licensePlate')!
      .value.toUpperCase();
    const type: string = this.optionsForm.get('type')!.value;

    switch (type) {
      case 'VEHICLE':
        this.selectedEntity = <Vehicle>await this.api.GetVehicle(licensePlate);
        break;
      case 'ENVASADO':
        this.selectedEntity = <Envasado>(
          await this.api.GetEnvasado(licensePlate)
        );
        break;
      case 'CISTERNA':
        this.selectedEntity = <Cisterna>(
          await this.api.GetCisterna(licensePlate)
        );
        break;
      case 'TRACTO':
        this.selectedEntity = <Tracto>await this.api.GetTracto(licensePlate);
        break;
      case 'TANQUE':
        this.selectedEntity = <Tanque>await this.api.GetTanque(licensePlate);
        break;
      case 'SEMIRREMOLQUE':
        this.selectedEntity = <Semirremolque>(
          await this.api.GetSemirremolque(licensePlate)
        );
        break;
      default:
        this.selectedEntity = null;
        break;
    }
    if (this.isRTC) {
      return Boolean(this.selectedEntity);
    } else {
      return this.alloyRole(this.selectedEntity);
    }
  }

  /**
   * Actualiza la lista de tipos de vehículos cuando se
   * cambia el negocio seleccionado.
   */
  onBusinessChanged() {
    this.businessSelected = this.optionsForm.get('business')!.value;
    this.typesForBusinessSelected = this.typeOptions[this.businessSelected]!;
    // Reseteo de tipo de vehículo.
    this.optionsForm.get('type')?.reset('');
  }

  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.optionsForm);
  }
  /**
   * Filtra la lista para mostrar
   * solo el negocio del usuario
   */
  alloyBusiness(userBusiness: string): void {
    if (this.isRTC) {
      return;
    } else {
      this.master.BUSINESSES = this.master.BUSINESSES.filter(
        (business: Master) => business.valueId === userBusiness
      );
    }
  }
  /**
   * Permite mostrar la entidad
   * consultada solo a los usuarios que
   * pertenescan al mismo centro y con el rol correspondiente
   * @param entity
   * @returns {boolean}
   */
  alloyRole(entity: entities = null): boolean {
    if (entity !== null) {
      console.log(this.user);
      console.log(entity);
      switch (this.userRole) {
        case 'ROL_ADMINISTRADOR':
          if (this.user.hasAccessTo?.includes(entity.business)) {
            return true;
          }
          break;
        case 'ROL_TRANSPORTISTA':
          if (entity.carrier.carrierId == this.user.company) {
            return true;
          }
          break;
        case 'ROL_VISUALIZADOR':
        case 'ROL_APROBADOR':
          if (
            this.user.centers?.includes(entity.center) &&
            this.user.company === entity.carrier.carrierId
          ) {
            return true;
          }
          break;
        default:
          return false;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this.optionsSelectedSubscription.unsubscribe();
    this.disableLoadButtonSubscription.unsubscribe();
    this.businessSubscription.unsubscribe();
    console.log('rtc-evaluation.component subscriptions removed.');
  }
}
