<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
  <button
    id="details-modal-close"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="d('Cross')"
  ></button>
</div>
<div class="modal-body">
  <p>
    <strong>{{ modalQuestion }}</strong>
  </p>
  <p></p>
  <form [formGroup]="archiveForm">
    <div class="container-fluid">
      <div class="row my-1">
        <div class="col-sm">
          <label class="form-label">{{ modalMessage }}</label>
          <select
            id="motiveForArchiving"
            formControlName="motiveForArchiving"
            class="form-select"
            (change)="onChangeMotiveForArchiving()"
          >
            <option value="" selected>Seleccionar...</option>
            <option
              *ngFor="let motiveForArchiving of archiveMotives"
              [ngValue]="motiveForArchiving.valueId"
            >
              {{ motiveForArchiving.valueToDisplay }}
            </option>
          </select>
        </div>
      </div>
      <div class="row my-1">
        <div class="col-sm">
          <label for="comment" class="form-label"
            >Comentario
            <span *ngIf="false" class="text-danger fs-4">*</span></label
          >
          <div class="form-group">
            <input
              type="text"
              id="comment"
              formControlName="comment"
              class="form-control immutable-field"
              placeholder="máximo 300 caracteres"
              appInputTrim
            />
            <div *ngIf="showHelper(notificationsControl.get('comment'))">
              <small class="form-text text-danger text-muted">
                {{ helperMessages(notificationsControl.get("comment")) }}
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12" formArrayName="documents" *ngIf="documentRequired">
        <div
          class="row border-top"
          id="cisterna-documents"
          *ngFor="let documentControl of documentsControl; let i = index"
          [formGroupName]="i"
        >
          <div class="row my-2">
            <div class="col-sm-11">
              <input
                type="text"
                id="documentName"
                class="form-control-plaintext form-control-lg"
                formControlName="documentName"
                readonly
              />
            </div>
            <div class="col-sm-1">
              <p class="text-danger fs-4">*</p>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-sm-12">
              <input
                type="file"
                accept=".pdf"
                id="documentFile"
                formControlName="documentFile"
                class="form-control"
                (change)="onChangeDocumentFile($event, i)"
              />
              <div *ngIf="showHelper(documentControl.get('documentFile'))">
                <small class="form-text text-muted">
                  {{ helperMessages(documentControl.get("documentFile")) }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    id="details-modal-show"
    type="button"
    class="btn btn-outline-secondary mx-2"
    (click)="showForm()"
    *ngIf="isDev"
  >
    Log Form
  </button>
  <button
    id="details-modal-cancel"
    type="button"
    class="btn btn-outline-danger"
    (click)="d('Cancel')"
  >
    Cancelar
  </button>
  <button
    id="details-modal-accept"
    type="button"
    class="btn btn-outline-primary"
    (click)="c('Accept')"
    [disabled]="!archiveForm.valid"
  >
    Aceptar
  </button>
</div>
