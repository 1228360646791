import { Component } from '@angular/core';

@Component({
  selector: 'app-tractos',
  templateUrl: './tractos.component.html',
  styleUrls: ['./tractos.component.css'],
})
export class TractosComponent {
  entityName: string = 'Tractos';
}
