import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import {
  APIService,
  CreateNotificationInput,
  DeleteEnvasadoInput,
  UpdateDocumentInput,
  UpdateEnvasadoInput,
  UpdateEnvasadoMutation,
  Envasado,
} from '../../../app-sync.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { EnvasadosService } from '../envasados.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { DocumentsService } from '../../documents/documents.service';
import { UsersService } from '../../users/users.service';
import { EditionsService } from '../../../shared/services/editions.service';
import { ApiRequestsService } from '../../../shared/services/api-requests.service';
import { MasterService } from '../../master/master.service';
import { FormsService } from '../../../shared/services/forms.service';
import { ArchivesService } from '../../../shared/services/archives.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { CommonsService } from '../../../shared/services/commons.service';

@Component({
  selector: 'app-envasado-details',
  templateUrl: './envasado-details.component.html',
  styleUrls: ['./envasado-details.component.css'],
})
export class EnvasadoDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('envasadoModal', { static: false }) envasadoModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('updateEnvasadoAttributeModal', { static: false })
  updateEnvasadoAttributeModal: TemplateRef<any> | undefined;
  @ViewChild('archiveEnvasadoModal', { static: false })
  archiveEnvasadoModal: TemplateRef<any> | undefined;
  currentYear: number = new Date().getFullYear();
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isCarrier: boolean = false;
  isBeingRejected: boolean = false;
  isGettingEnvasado: boolean = false;
  modalMessage: string = '';
  modalQuestion: string = '';
  modalTitle: string = '';
  modalWithComment: boolean = false;
  archiveForm: FormGroup;
  commentForm: FormGroup;
  updateAttributeForm: FormGroup;
  envasado: Envasado;
  envasadoNeedsApproval: boolean = false;
  envasadoCanBeRejected: boolean = false;
  showBlockingMotives: boolean = false;
  hasComment: boolean = false;
  showComment: boolean = false;

  private selectedEnvasadoChangedSubscription: Subscription =
    new Subscription();
  private onUpdateEnvasadoListener: ZenObservable.Subscription =
    new Subscription();
  private requestSucceededSubscription: Subscription = new Subscription();

  constructor(
    private api: APIService,
    private apiRequestsService: ApiRequestsService,
    private documentsService: DocumentsService,
    private editionsService: EditionsService,
    private envasadosService: EnvasadosService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private modalsService: ModalsService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private formsService: FormsService,
    private archivesService: ArchivesService,
    private commonsService: CommonsService
  ) {
    this.envasado = this.envasadosService.getSelectedEnvasado();
    this.archiveForm = new FormGroup({
      motiveForArchiving: new FormControl(null),
      comment: new FormControl(null),
      documents: new FormArray([
        new FormGroup({
          documentMasterValueId: new FormControl(null),
          documentName: new FormControl(null),
          documentIssueDate: new FormControl(null),
          documentExpirationDate: new FormControl(null),
          documentFile: new FormControl(null),
          documentSourceFile: new FormControl(null),
        }),
      ]),
    });
    this.commentForm = new FormGroup({
      comment: new FormControl(null),
    });
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(null),
    });
  }

  async ngOnInit() {
    this.selectedEnvasadoChangedSubscription =
      this.envasadosService.selectedEnvasadoChanged.subscribe(
        (envasado: Envasado) => {
          this.envasado = envasado;
          this.setEnvasadoSituation();
        }
      );

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateSelectedEnvasado();

    // Subscripción a actualizaciones del Envasado Rígido
    this.onUpdateEnvasadoListener = this.api
      .OnUpdateEnvasadoListener(
        this.envasadosService.getSelectedEnvasadoFilterForSubscriptions()
      )
      .subscribe((response) => {
        if (response) {
          this.envasadosService.refreshSelectedEnvasado();
        }
      });

    // Subscripción a consultas de API exitosas
    this.requestSucceededSubscription =
      this.apiRequestsService.requestSucceeded.subscribe(
        (requestSucceeded: boolean): void => {
          if (requestSucceeded) {
            this.onRefreshEnvasado();
          }
        }
      );
  }

  /**
   * Actualiza el envasado seleccionado.
   * @return {Promise}
   * @private
   */
  private async updateSelectedEnvasado(): Promise<void> {
    this.isGettingEnvasado = true;
    await this.envasadosService.refreshSelectedEnvasado().then(() => {
      this.setEnvasadoSituation();
      this.isGettingEnvasado = false;
    });
  }

  /**
   * Ejecuta el modal de aprobación de un envasado y pide su
   * actualización en caso de ser aprobado.
   * @return {Promise}
   */
  async onApproveEnvasado(): Promise<void> {
    this.initCommentForm();
    this.modalTitle = appConstants.envasado.modalMessages.approve.title;
    this.modalQuestion =
      appConstants.envasado.modalMessages.approve.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.envasado.sapId!,
          this.envasado.licensePlate
        )
      );
    this.modalMessage = appConstants.envasado.modalMessages.approve.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.envasadoModal
    );

    if (modalResult) {
      this.isGettingEnvasado = true;
      await this.updateEnvasado('approve', this.commentForm.value.comment);
    }
  }

  /**
   * Ejecuta el modal de rechazo de un envasado rígido y pide su
   * actualización en caso de ser rechazado.
   * @return {Promise}
   */
  async onRejectEnvasado(): Promise<void> {
    this.isBeingRejected = true;
    this.initCommentForm();
    this.modalTitle = appConstants.envasado.modalMessages.reject.title;
    this.modalQuestion =
      appConstants.envasado.modalMessages.reject.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.envasado.sapId!,
          this.envasado.licensePlate
        )
      );
    this.modalMessage = appConstants.envasado.modalMessages.reject.message;
    this.modalWithComment = true;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.envasadoModal
    );

    if (modalResult) {
      this.isGettingEnvasado = true;
      console.log(' onRejectEnvasado @ envasado-details.component ');
      console.log(this.commentForm.value);
      const newNotification = this.commentForm.value;
      const today: Date = new Date();
      const todayTimestamp: string = Math.floor(
        today.getTime() / 1000
      ).toString();
      const creatNotificationInput: CreateNotificationInput = {
        businessId: this.envasado.business,
        notificationId:
          'ENVASADO#' + this.envasado.envasadoId + '#' + todayTimestamp,
        status: 'TO_BE_SENT',
        model: 'ENVASADO',
        motive: 'APPROVE#REJECTED',
        envasadoId: this.envasado.envasadoId,
        comment: newNotification.comment,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async (): Promise<void> => {
          await this.updateEnvasado('reject', newNotification.comment);
        });
    }

    this.isBeingRejected = false;
  }

  /**
   * Ejecuta el modal de archivado de un envasado y dirige
   * al componente de edición en caso de ser archivado.
   * @return {Promise}
   */
  async onArchiveEnvasado(): Promise<void> {
    this.archivesService.setArchiving(true);
    this.initArchiveForm();
    this.modalTitle = appConstants.envasado.modalMessages.archive.title;
    this.modalQuestion =
      appConstants.envasado.modalMessages.archive.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.envasado.sapId!,
          this.envasado.licensePlate
        )
      );
    this.modalMessage = appConstants.envasado.modalMessages.archive.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.archiveEnvasadoModal
    );

    if (modalResult) {
      console.log(' onArchiveEnvasado @ envasado-details.component ');
      console.log(this.archiveForm.value);

      let documents: DocumentFromForms[] = [];
      if (this.archiveForm.get('documents') !== null) {
        documents = this.archiveForm.value.documents.slice();
      }

      // 1. Se crea el archivo.
      await this.archivesService.createArchive(
        this.envasado,
        this.archiveForm.value.motiveForArchiving,
        this.archiveForm.value.comment,
        documents
      );

      // 2. Dirigimos a la creación de la nueva entidad.
      this.router
        .navigate(['../new'], { relativeTo: this.route })
        .then(() => console.log('navigate to new'));
    }
  }

  /**
   * Actualiza un envasado.
   * @param {string} action Acción a realizar, puede ser 'approve' o 'reject'.
   * @param {string} comment Comentario de aprobación o rechazo.
   * @return {Promise}
   * @private
   */
  private async updateEnvasado(
    action: 'approve' | 'reject',
    comment: string | null = null
  ): Promise<void> {
    this.isGettingEnvasado = true;
    let newEnvasadoStatus: string;
    let feedbackMessage: string = `Envasado ${this.commonsService.getIdentification(
      this.envasado.sapId!,
      this.envasado.licensePlate
    )} `;
    let catchFeedbackMessage: string = `Error al _ envasado ${this.commonsService.getIdentification(
      this.envasado.sapId!,
      this.envasado.licensePlate
    )}`;

    switch (action) {
      case 'approve':
        // Se cambia el estado del envasado a "Procesando"
        newEnvasadoStatus = `${this.envasado.business}_${appConstants.entity.codes.inProcess}`;
        feedbackMessage = feedbackMessage + 'aprobado. Actualizando';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'aprobar');
        break;
      case 'reject':
        // Se cambia el estado del envasado a "Rechazado"
        newEnvasadoStatus = `${this.envasado.business}_${appConstants.entity.codes.rejected}`;
        feedbackMessage = feedbackMessage + 'rechazado. Enviando notificación';
        catchFeedbackMessage = catchFeedbackMessage.replace('_', 'rechazar');
        break;
    }

    const updateInputEnvasado: UpdateEnvasadoInput = {
      envasadoId: this.envasado.envasadoId,
      status: newEnvasadoStatus,
      comment: comment,
    };

    await this.api
      .UpdateEnvasado(updateInputEnvasado)
      .then(async (envasado: UpdateEnvasadoMutation): Promise<void> => {
        this.feedbacksService.showFeedback(feedbackMessage, 'info');

        if (action === 'approve') {
          // Se cambia el estado de los documentos con estado "Por Aprobar"
          // al estado "Procesando"
          for (let document of envasado.documents!.items) {
            if (
              document?.status ===
              `${envasado.business}_${appConstants.document.codes.toBeApproved}`
            ) {
              const updateDocumentInput: UpdateDocumentInput = {
                documentId: document.documentId,
                name: document.name,
                status: `${envasado.business}_${appConstants.document.codes.inProcess}`,
              };
              await this.documentsService.updateDocument(updateDocumentInput);
            }
          }
        }

        // Actualizamos el envasado seleccionado.
        await this.updateSelectedEnvasado();
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          catchFeedbackMessage
        );
      });
  }

  /**
   * Ejecuta el modal de edición de atributos del Envasado Rígido.
   * @return {Promise}
   */
  async onUpdateEnvasado(): Promise<void> {
    this.initUpdateAttributeForm();

    this.modalTitle = appConstants.envasado.modalMessages.edit.title;
    this.modalQuestion =
      appConstants.envasado.modalMessages.edit.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.envasado.sapId!,
          this.envasado.licensePlate
        )
      );
    this.modalMessage = appConstants.envasado.modalMessages.edit.message;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.updateEnvasadoAttributeModal
    );

    if (modalResult) {
      if (this.updateAttributeForm.invalid) {
        this.feedbacksService.showFeedback(
          'Formulario inválido, no será procesada la petición.',
          'danger'
        );
        return;
      }

      const patchedEnvasado = this.updateAttributeForm.value;
      const payload: { [key: string]: string } = {
        center: patchedEnvasado.center,
      };

      this.apiRequestsService.updateEntity(
        this.envasado.business,
        this.envasado.__typename,
        this.commonsService.getIdentification(
          this.envasado.sapId!,
          this.envasado.licensePlate
        ),
        payload
      );
    }
  }

  /**
   * Navega al formulario de edición del envasado.
   * @return {Promise}
   */
  async onEditEnvasado(): Promise<void> {
    const editionId: string = `${this.usersService.business.value.toUpperCase()}#ENVASADO#${
      this.envasado.envasadoId
    }`;

    if (await this.editionsService.editionIsTaken(editionId)) {
      return;
    }

    this.router
      .navigate(['edit'], { relativeTo: this.route })
      .then(() => console.log('navigate to edit'));
  }

  /**
   * Ejecuta el modal de eliminación de un envasado y lo
   * elimina en caso de aceptar.
   * @return {Promise}
   */
  async onDeleteEnvasado(): Promise<void> {
    this.modalTitle = appConstants.envasado.modalMessages.delete.title;
    this.modalQuestion =
      appConstants.envasado.modalMessages.delete.question.replace(
        '_',
        this.commonsService.getIdentification(
          this.envasado.sapId!,
          this.envasado.licensePlate
        )
      );
    this.modalMessage = appConstants.envasado.modalMessages.delete.message;
    this.modalWithComment = false;

    let modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.envasadoModal
    );

    if (modalResult) {
      const deleteEnvasadoInput: DeleteEnvasadoInput = {
        envasadoId: this.envasado.envasadoId,
      };

      await this.api
        .DeleteEnvasado(deleteEnvasadoInput)
        .then(async () => {
          this.feedbacksService.showFeedback(
            `Envasado ${this.commonsService.getIdentification(
              this.envasado.sapId!,
              this.envasado.licensePlate
            )} eliminado.`,
            'info'
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al borrar envasado ${this.commonsService.getIdentification(
              this.envasado.sapId!,
              this.envasado.licensePlate
            )}`
          );
        });
      this.router
        .navigate(['/', this.envasado.business.toLowerCase(), 'envasados'])
        .then(() => console.log('navigate to envasados'));
    }
  }

  /**
   * Llama a refrescar el envasado seleccionado.
   */
  onRefreshEnvasado(): void {
    this.updateSelectedEnvasado().then(() =>
      console.log('Envasado seleccionado actualizado.')
    );
  }

  /**
   * Colapsa o muestra el comentario del vehículo.
   */
  onViewComment(): void {
    this.showComment = !this.showComment;
  }

  /**
   * Retorna la clase que colapsa o muestra el comentario del vehículo.
   * @return {string} Clase CSS
   */
  showCommentClass(): string {
    return this.showComment ? 'show' : '';
  }

  /**
   * Inicializa el formulario de rechazo de un envasado.
   * @private
   */
  private initCommentForm(): void {
    // Inicialización del formulario
    this.commentForm = this.formsService.generateCommentForm(
      this.isBeingRejected
    );
  }

  /**
   * Inicializa el formulario de archivado de un envasado.
   * @private
   */
  private initArchiveForm(): void {
    // Inicialización del formulario
    this.archiveForm = this.formsService.generateArchiveForm([], {});
  }

  /**
   * Inicializa el formulario de actualización de
   * atributos de un Envasado Rígido.
   * @private
   */
  private initUpdateAttributeForm(): void {
    // Inicialización del formulario
    this.updateAttributeForm = new FormGroup({
      center: new FormControl(this.envasado.center, [Validators.required]),
    });
  }

  /**
   * Indica si un envasado tiene documentos por vencer.
   * @return {boolean} Boolean que indica si el envasado tiene documentos por vencer.
   */
  hasToExpireDocuments(): boolean {
    const docNumber: number = this.envasado.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un envasado tiene documentos vencidos.
   * @return {boolean} Boolean que indica si el envasado tiene documentos vencidos.
   */
  hasExpiredDocuments(): boolean {
    const docNumber: number = this.envasado.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna un color dependiendo del estado del envasado.
   * @return {string}
   */
  getColor(): string {
    return this.masterService.getSatusColor(this.envasado.status);
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del envasado.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Retorna una clase CSS dependiendo de la edad del envasado.
   * @return {string}
   */
  getEnvasadoAgeClass(age: number): string {
    let envasadoAgeClass: string = '';
    if (age >= appConstants.envasado.ageToBeConsideredOld) {
      envasadoAgeClass = 'text-danger';
    }
    return envasadoAgeClass;
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    switch (button) {
      case 'delete':
      case 'archive':
        show = this.isAdmin;
        break;
      case 'edit-center':
        show = this.isAdmin && this.envasado.business === 'LUBRICANTS';
        break;
      case 'approve':
      case 'reject':
        show = this.isAdmin || this.isApprover;
        break;
      case 'actions':
      case 'edit':
        show = this.isAdmin || this.isCarrier;
        break;
      default:
        break;
    }
    return show;
  }

  /**
   * Define la situación del envasado: ¿necesita aprobación?, ¿puede ser rechazado?,
   * ¿Está bloqueado?
   * @private
   */
  private setEnvasadoSituation(): void {
    const envasadoToBeApprove: boolean =
      this.envasado.status ===
      `${this.envasado.business}_${appConstants.entity.codes.toBeApproved}`;
    const envasadoRejected: boolean =
      this.envasado.status ===
      `${this.envasado.business}_${appConstants.entity.codes.rejected}`;
    this.envasadoNeedsApproval = envasadoToBeApprove || envasadoRejected;
    this.envasadoCanBeRejected = envasadoToBeApprove;
    this.showBlockingMotives =
      this.envasado.blockingMotives !== null &&
      this.envasado.blockingMotives !== undefined &&
      this.envasado.blockingMotives.length > 0 &&
      this.envasado.status !==
        `${this.envasado.business}_${appConstants.entity.codes.available}`;
    this.hasComment = !!this.envasado.comment;
    this.showComment = false;
  }

  ngOnDestroy(): void {
    this.selectedEnvasadoChangedSubscription.unsubscribe();
    this.onUpdateEnvasadoListener.unsubscribe();
    this.requestSucceededSubscription.unsubscribe();
    console.log('envasado-details.component subscriptions removed.');
  }
}
