<div class="row align-items-end">
  <div class="col-sm-6 text-center" *ngIf="!isGettingCisterna">
    <div>
      <small id="cisterna-dates" class="text-muted">
        Creado: {{ cisterna.createdAt | dateFormat | dateWithoutSeconds }}
        <br />
        Actualizado: {{ cisterna.updatedAt | dateFormat | dateWithoutSeconds }}
      </small>
    </div>
    <div class="btn-group divCenter">
      <button
        id="cisterna-details-approve"
        type="button"
        class="btn btn-outline-primary my-2"
        (click)="onApproveCisterna()"
        *ngIf="cisternaNeedsApproval && showButton('approve')"
      >
        Aprobar
      </button>
      <button
        id="cisterna-details-reject"
        type="button"
        class="btn btn-outline-danger my-2"
        (click)="onRejectCisterna()"
        *ngIf="cisternaCanBeRejected && showButton('reject')"
      >
        Rechazar
      </button>
    </div>
  </div>
  <div class="col-sm-6 text-end">
    <div class="btn-group" style="padding-top: 60px">
      <div ngbDropdown class="d-inline-block">
        <button
          type="button"
          class="btn btn-outline-primary dropdownButton mx-2"
          id="cisterna-details-actions"
          *ngIf="!isGettingCisterna && showButton('actions')"
          ngbDropdownToggle
        >
          Acciones
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownActions">
          <a
            id="cisterna-details-edit"
            *ngIf="!isGettingCisterna && showButton('edit')"
            (click)="onEditCisterna()"
            class="dropdown-item"
            ngbDropdownItem
          >
            Actualizar documentos</a
          >
          <div class="dropdown-divider"></div>
          <a
            id="cisterna-details-archive"
            *ngIf="!isGettingCisterna && showButton('archive')"
            (click)="onArchiveCisterna()"
            ngbDropdownItem
          >
            Archivar</a
          >
          <a
            id="cisterna-details-delete"
            *ngIf="!isGettingCisterna && showButton('delete')"
            (click)="onDeleteCisterna()"
            ngbDropdownItem
          >
            Borrar</a
          >
        </div>
      </div>
      <button
        id="cisterna-details-refresh"
        class="btn btn-outline-secondary"
        *ngIf="!isGettingCisterna"
        (click)="onRefreshCisterna()"
      >
        <span>
          <img
            ngSrc="assets/images/icons/actualizar.svg"
            height="25"
            width="25"
            alt="Actualizar"
          />
        </span>
      </button>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-sm-6 text-center my-5" *ngIf="isGettingCisterna">
    <app-spinner message="Cargando detalles del vehículo..."></app-spinner>
  </div>
  <div
    class="col-sm-6 box-details-cab"
    [ngStyle]="{ border: '3px solid ' + getColor() }"
    *ngIf="!isGettingCisterna"
  >
    <div class="div-box-sap-id">
      <h4 [ngStyle]="{ color: getColor(), 'font-weight': 'bold' }">
        SAP ID: {{ cisterna.sapId! | sapId }}
      </h4>
    </div>
    <div style="display: inline">
      <p><strong>Transportista</strong>: <br />{{ cisterna.carrier!.name }}</p>
      <p><strong>Patente</strong>: <br />{{ cisterna.licensePlate }}</p>
      <div class="divCenter" style="text-align: center">
        <p *ngIf="showBlockingMotives">
          <strong
            >Bloqueado el
            {{
              cisterna.lastStatusInformedAt || cisterna.updatedAt
                | dateFormat
                | dateWithoutSeconds
            }}
            por</strong
          >:<br />
          <span
            class="badge border rounded-4 text-bg-light mx-1"
            *ngFor="let motive of cisterna.blockingMotives"
          >
            {{ motive }}
          </span>
        </p>
      </div>
    </div>

    <div class="divCenter">
      <h5>
        <span
          class="badge"
          [ngStyle]="{ 'background-color': getColor() }"
          style="width: 200px; border-radius: 60px"
        >
          <span>
            <img
              [src]="getImageSrc(cisterna.status)"
              height="25"
              width="25"
              alt="Estado"
            />
          </span>
          {{ cisterna.status | master : "VEHICLES#STATUSES" }}</span
        >
      </h5>
      <div *ngIf="hasComment">
        <label
          class="custom-file-upload pt-2 mx-1 float-end text-secondary"
          style="cursor: pointer"
        >
          <a id="entity-comment" title="Comentario" (click)="onViewComment()">
            <span class="material-symbols-outlined">comment</span>
          </a>
        </label>
      </div>
    </div>
    <div class="divCenter mb-2" *ngIf="hasComment && showComment">
      <div class="collapse comment" [ngClass]="showCommentClass()">
        <div class="card card-body">
          {{ cisterna.comment }}
        </div>
      </div>
    </div>
    <div class="divCenter">
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasToExpireDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            cisterna.documentsToExpire
          }}</span>
          Por vencer</span
        >
      </h6>
      <h6>
        <span
          class="badge border rounded-4 text-bg-light"
          *ngIf="hasExpiredDocuments()"
        >
          <span class="badge border rounded-circle text-bg-light">{{
            cisterna.documentsExpired
          }}</span>
          Vencido</span
        >
      </h6>
    </div>

    <div ngbAccordion style="padding: 10px">
      <div ngbAccordionItem [collapsed]="true">
        <h2 ngbAccordionHeader>
          <button id="cisterna-details-accordion" ngbAccordionButton>
            Ver detalles
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="table-container">
            <ng-template>
              <table class="table table-hover">
                <caption>
                  Detalles del Vehículo
                </caption>
                <tbody>
                  <tr>
                    <th scope="row">Centro</th>
                    <td>{{ cisterna.originalCenter! | master : "CENTERS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Sub-Negocio</th>
                    <td>
                      {{ cisterna.subBusiness | master : "SUB_BUSINESSES" }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Marca</th>
                    <td>{{ cisterna.brand | master : "VEHICLES#BRANDS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Modelo</th>
                    <td>{{ cisterna.model }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Año de Fabricación</th>
                    <td>{{ cisterna.year }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Antigüedad</th>
                    <td
                      [ngClass]="
                        getCisternaAgeClass(currentYear - cisterna.year)
                      "
                    >
                      {{ currentYear - cisterna.year }} año{{
                        currentYear - cisterna.year === 1 ? "" : "s"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Color</th>
                    <td>{{ cisterna.color | master : "VEHICLES#COLORS" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo</th>
                    <td>{{ cisterna.type | master : "CISTERNAS#TYPES" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Motor</th>
                    <td>
                      {{
                        cisterna.engineType! | master : "CISTERNAS#ENGINE_TYPES"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Capacidad</th>
                    <td>
                      {{ cisterna.capacity | master : "CISTERNAS#CAPACITIES" }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Mamparo</th>
                    <td>
                      {{
                        cisterna.bulkheadType!
                          | master : "CISTERNAS#BULKHEAD_TYPES"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Tiene Bomba</th>
                    <td>{{ cisterna.hasPump ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Usado en Minería</th>
                    <td>{{ cisterna.forMining ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Acreditación Ranchera</th>
                    <td>{{ cisterna.hasRanchAccreditation ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Es Spot</th>
                    <td>{{ cisterna.isSpot ? "Sí" : "No" }}</td>
                  </tr>
                  <tr>
                    <th scope="row">GPS IMEI</th>
                    <td>{{ cisterna.gpsImei }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Cámara IMEI</th>
                    <td>{{ cisterna.cameraImei }}</td>
                  </tr>
                  <tr>
                    <th class="align-middle" scope="row">Centro de Apoyo</th>
                    <td>
                      <div>
                        <label
                          *ngIf="showButton('edit-center')"
                          class="float-start me-1"
                          style="cursor: pointer"
                        >
                          <a
                            id="update-center"
                            title="Actualizar Centro de Apoyo"
                            (click)="onUpdateCisterna()"
                          >
                            <span
                              class="material-symbols-outlined text-secondary"
                              >edit_square</span
                            >
                          </a>
                        </label>
                        {{ cisterna.center | master : "CENTERS" }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center">
                <img
                  [src]="capacityImage()"
                  alt="Cisterna"
                  height="200"
                  width="256"
                />
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <app-documents></app-documents>
  </div>
</div>

<!-- Modals -->
<ng-template #cisternaModal let-c="close" let-d="dismiss">
  <app-modal-with-comment
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [showCommentForm]="modalWithComment"
    [mandatoryComment]="isBeingRejected"
    [commentForm]="commentForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-with-comment>
</ng-template>

<!-- Update Attribute Modal -->
<ng-template #updateCisternaAttributeModal let-c="close" let-d="dismiss">
  <app-modal-change-attributes
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [attributeForm]="updateAttributeForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-change-attributes>
</ng-template>

<!-- Archive Modal -->
<ng-template #archiveCisternaModal let-c="close" let-d="dismiss">
  <app-modal-for-archiving
    [modalTitle]="modalTitle"
    [modalQuestion]="modalQuestion"
    [modalMessage]="modalMessage"
    [archiveForm]="archiveForm"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-modal-for-archiving>
</ng-template>
