import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonsService {
  /**
   * Retorna la identificación de una entidad con
   * formato "sapId - id" usado en modales y feedbacks.
   * @return {string}
   */
  getIdentification(sapId: string, id: string): string {
    let sapIdParsed: string =
      sapId === 'Sin Asignar' ? sapId : parseInt(sapId).toString();
    return sapIdParsed + ' - ' + id;
  }

  /**
   * Recibe una fecha en formato "YYYY-MM-DD", le suma una cantidad de días
   * igual al valor de days y retorna la fecha con el mismo formato "YYYY-MM-DD".
   * @param {string} dateString Fecha en formato "YYYY-MM-DD".
   * @param {number} days Número de días a añadir.
   */
  addDays(dateString: string, days: number): string {
    const [year, month, day] = dateString.split('-').map(Number);
    // Crear la fecha de forma explícita (meses en JavaScript son indexados desde 0)
    const date = new Date(year, month - 1, day, 0, 0, 0);

    // Sumar un día
    date.setDate(date.getDate() + days);

    // Formatear de nuevo a "YYYY-MM-DD"
    const newYear = date.getFullYear();
    const newMonth = String(date.getMonth() + 1).padStart(2, '0');
    const newDay = String(date.getDate()).padStart(2, '0');

    return `${newYear}-${newMonth}-${newDay}`;
  }
}
