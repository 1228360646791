import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalBDService {
  // Método para abrir la base de datos
  openDatabase(): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open('RTC-EvaluationsDB', 1);
      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBRequest).result;
        if (!db.objectStoreNames.contains('RTC')) {
          db.createObjectStore('RTC', { keyPath: 'plate' });
        }
      };
      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };
      request.onerror = (event) => {
        reject('Error al abrir la base de datos: ' + event);
      };
    });
  }
  /**
   *
   * @param formData all data of current form
   * @param id plate of vehicle evaluated
   * @returns
   */
  // Método para almacenar datos con un ID personalizado
  saveFormData(formData: any, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.openDatabase()
        .then((db) => {
          const transaction = db.transaction('RTC', 'readwrite');
          const store = transaction.objectStore('RTC');
          // Establecer el id patente en el objeto de datos
          formData.plate = id;
          const date = new Date();
          formData.updatedAt = date.toISOString();
          const request = store.put(formData);
          request.onsuccess = () => {
            resolve();
          };
          request.onerror = (event) => {
            reject('Error al almacenar los datos: ' + event);
          };
        })
        .catch((error) => {
          reject('Error al abrir la base de datos: ' + error);
        });
    });
  }

  // Método para obtener datos por ID personalizado
  getFormData(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.openDatabase()
        .then((db) => {
          const transaction = db.transaction('RTC', 'readonly');
          const store = transaction.objectStore('RTC');
          const request = store.get(id);
          request.onsuccess = (event) => {
            const data = request.result;
            if (data) {
              resolve(data); // Si se encuentra, devolvemos los datos
            } else {
              reject(`No se encontraron datos con el ID: ${id}`); // Si no se encuentra, rechazamos la promesa
            }
          };

          request.onerror = (event) => {
            reject('Error al recuperar los datos: ' + event);
          };
        })
        .catch((error) => {
          reject('Error al abrir la base de datos: ' + error);
        });
    });
  }
  /**
   * elimina el form del almacenamiento local
   * @param id la patente asociadad al form
   * @returns
   */
  deleteFormData(id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.openDatabase()
        .then((db) => {
          const transaction = db.transaction('RTC', 'readwrite');
          const store = transaction.objectStore('RTC');

          const request = store.delete(id); // Eliminamos el registro con la clave 'id'

          request.onsuccess = () => {
            resolve(); // Confirmar éxito
          };

          request.onerror = (event) => {
            reject('Error al eliminar el registro: ' + event);
          };
        })
        .catch((error) => {
          reject('Error al abrir la base de datos: ' + error);
        });
    });
  }
}
