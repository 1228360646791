import { Injectable } from '@angular/core';
import {
  APIService,
  Couple,
  CouplesByBusinessAndCoupleIdQuery,
  CouplesByCenterAndCoupleIdQuery,
  CouplesByCompanyAndCoupleIdQuery,
  ModelSubscriptionCoupleFilterInput,
  User,
} from '../../app-sync.service';
import { Subject } from 'rxjs';
import { UsersService } from '../users/users.service';
import { filterInputs } from '../../shared/types/filter-inputs';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { nextToken } from '../../shared/types/next-token';

@Injectable({
  providedIn: 'root',
})
export class CouplingsService {
  couplesChanged = new Subject<Couple[]>();

  private couples: Couple[] = [];
  private couplesFilterForSubscriptions: ModelSubscriptionCoupleFilterInput =
    {};
  constructor(
    private api: APIService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService
  ) {
    this.setCouplesFilter();
  }

  // --------------------------------------
  // Métodos para la lista de Acoplamientos
  // --------------------------------------

  /**
   * Configura la lista de acoplamientos para referencia de
   * todos los componentes.
   * @param {Couple[]} couples Lista de acoplamientos.
   */
  setCouples(couples: Couple[]): void {
    this.couples = couples;
    this.setCouplesFilter();
    this.couplesChanged.next(this.couples.slice());
  }

  /**
   * Actualiza la lista de acoplamientos.
   * @return {Promise}
   */
  async refreshCouples(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListCouples: Couple[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListCouples = await this.listCouplesByCarrier(activeUser);
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListCouples = await this.listCouplesByBusiness(business);
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListCouples = await this.listCouplesByCenter(activeUser, business);
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo acoplamientos. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setCouples(tempListCouples.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @private
   */
  private async listCouplesByBusiness(business: string): Promise<Couple[]> {
    let listCouplesResult: CouplesByBusinessAndCoupleIdQuery;
    listCouplesResult = await this.api.CouplesByBusinessAndCoupleId(business);

    let tempListCouples: Couple[] = <Couple[]>listCouplesResult.items;
    let nextToken: nextToken = listCouplesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListCouplesResult: CouplesByBusinessAndCoupleIdQuery =
        await this.api.CouplesByBusinessAndCoupleId(
          business,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListCouples.push(...(<Couple[]>loopListCouplesResult.items));
      nextToken = loopListCouplesResult.nextToken;
    }
    return tempListCouples.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @private
   */
  private async listCouplesByCenter(
    activeUser: User,
    business: string
  ): Promise<Couple[]> {
    let tempListCouples: Couple[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const couplesPromises: Promise<Couple[]>[] = centers.map(
      async (center: string): Promise<Couple[]> => {
        let listCouplesResult: CouplesByCenterAndCoupleIdQuery;

        listCouplesResult = await this.api.CouplesByCenterAndCoupleId(center);
        let couples: Couple[] = <Couple[]>listCouplesResult.items;

        let nextToken: nextToken = listCouplesResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListCouplesResult: CouplesByCenterAndCoupleIdQuery =
            await this.api.CouplesByCenterAndCoupleId(
              center,
              undefined,
              undefined,
              undefined,
              100,
              nextToken
            );
          couples.push(...(<Couple[]>loopListCouplesResult.items));
          nextToken = loopListCouplesResult.nextToken;
        }

        return couples;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Couple[][] = await Promise.all(couplesPromises);

    // Combinar todos los resultados en una sola lista
    results.forEach((couples: Couple[]): void => {
      tempListCouples.push(...couples);
    });

    return tempListCouples.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @private
   */
  private async listCouplesByCarrier(activeUser: User): Promise<Couple[]> {
    let listCouplesResult: CouplesByCompanyAndCoupleIdQuery;
    listCouplesResult = await this.api.CouplesByCompanyAndCoupleId(
      activeUser.company
    );

    let tempListCouples: Couple[] = <Couple[]>listCouplesResult.items;
    let nextToken: nextToken = listCouplesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListCouplesResult: CouplesByCompanyAndCoupleIdQuery =
        await this.api.CouplesByCompanyAndCoupleId(
          activeUser.company,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListCouples.push(...(<Couple[]>loopListCouplesResult.items));
      nextToken = loopListCouplesResult.nextToken;
    }
    return tempListCouples.slice();
  }

  /**
   * Retorna la lista de acoplamientos.
   * @return {Couple[]}
   */
  getCouples(): Couple[] {
    return this.couples.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de acoplamientos.
   * @private
   */
  private setCouplesFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.couplesFilterForSubscriptions = <ModelSubscriptionCoupleFilterInput>(
      modelFilterInput
    );
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de acoplamientos.
   * @return {ModelSubscriptionCoupleFilterInput}
   */
  getCouplesFilterForSubscriptions(): ModelSubscriptionCoupleFilterInput {
    return { ...this.couplesFilterForSubscriptions };
  }
}
