import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZenObservable } from 'zen-observable-ts';

import { appConstants } from '../../../shared/constants/constants';

import { APIService, Tanque } from '../../../app-sync.service';
import { TanquesService } from '../tanques.service';
import { UsersService } from '../../users/users.service';
import { DataTableDirective } from 'angular-datatables';
import { DocumentsService } from '../../documents/documents.service';
import { MasterService } from '../../master/master.service';

@Component({
  selector: 'app-tanques-list',
  templateUrl: './tanques-list.component.html',
  styleUrls: ['./tanques-list.component.css'],
})
export class TanquesListComponent implements OnInit, OnDestroy {
  entityName: string = 'Tanques';
  dtOptions: DataTables.Settings = {};
  isGettingTanques: boolean = false;
  isAdmin: boolean = false;
  isApprover: boolean = false;
  isViewer: boolean = false;
  isCarrier: boolean = false;
  tanques: Tanque[];
  tanquesAttributes: string[] = [
    'SAP ID',
    'Estado',
    'Patente',
    'Transportista',
    'Centro',
    'Alertas',
    'Bloqueos',
  ];

  //Esto permite crear un filtro de búsqueda personalizado en el datatable
  searchText: string = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  private tanquesChangedSubscription: Subscription = new Subscription();
  private onDeleteTanqueListener: ZenObservable.Subscription =
    new Subscription();
  private onCreateTanqueListener: ZenObservable.Subscription =
    new Subscription();
  private onUpdateTanqueListener: ZenObservable.Subscription =
    new Subscription();

  constructor(
    private tanquesService: TanquesService,
    private api: APIService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private documentService: DocumentsService,
    private masterService: MasterService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.tanques = this.tanquesService.getTanques();
  }

  async ngOnInit(): Promise<void> {
    this.tanquesChangedSubscription =
      this.tanquesService.tanquesChanged.subscribe((tanques: Tanque[]) => {
        this.tanques = tanques;
      });

    this.isAdmin = this.usersService.isAdmin;
    this.isApprover = this.usersService.isApprover;
    this.isViewer = this.usersService.isViewer;
    this.isCarrier = this.usersService.isCarrier;

    await this.updateTanquesList();

    // Una vez cargada la lista de vehículos definimos los filtros para subscripciones
    const subscriptionTanquesFilter =
      this.tanquesService.getTanquesFilterForSubscriptions();
    // Subscripción a Vehículos eliminados
    this.onDeleteTanqueListener = this.api
      .OnDeleteTanqueListener(subscriptionTanquesFilter)
      .subscribe((response) => {
        if (response) {
          this.tanquesService.refreshTanques();
        }
      });
    // Subscripción a Vehículos actualizados
    this.onUpdateTanqueListener = this.api
      .OnUpdateTanqueListener(subscriptionTanquesFilter)
      .subscribe((response) => {
        if (response) {
          this.tanquesService.refreshTanques();
        }
      });
    // Subscripción a Vehículos creados
    this.onCreateTanqueListener = this.api
      .OnCreateTanqueListener(subscriptionTanquesFilter)
      .subscribe((response) => {
        if (response) {
          this.tanquesService.refreshTanques();
        }
      });
  }

  /**
   * Navega a al formulario de creación de Tanque.
   */
  onNewTanque(): void {
    this.router
      .navigate(['new'], { relativeTo: this.route })
      .then(() => console.log('navigate to new'));
  }

  /**
   * Llama al método que refresca la lista de Tanques.
   * @return {Promise}
   */
  async onRefresh(): Promise<void> {
    this.searchText = '';
    await this.updateTanquesList();
  }

  /**
   * Define el tanque que está seleccionado.
   * y navega al detalle del mismo.
   * @param {Tanque} tanque Tanque seleccionado de la lista.
   */
  onSelectedTanque(tanque: Tanque): void {
    this.documentService.isRefreshFilter.next(true);
    this.tanquesService.setSelectedTanque(tanque);
    this.router
      .navigate([tanque.tanqueId], { relativeTo: this.route })
      .then(() => console.log(`navigate to ${tanque.tanqueId}`));
  }

  /**
   * Indica si un tanque tiene documentos por vencer.
   * @param {Tanque} tanque Tanque de la lista.
   * @return {boolean} Boolean que indica si el Tanque tiene documentos por vencer.
   */
  hasToExpireDocuments(tanque: Tanque): boolean {
    const docNumber = tanque.documentsToExpire || 0;
    return docNumber > 0;
  }

  /**
   * Indica si un tanque tiene documentos vencidos.
   * @param {Tanque} tanque Tanque de la lista.
   * @return {boolean} Boolean que indica si el Tanque tiene documentos vencidos.
   */
  hasExpiredDocuments(tanque: Tanque): boolean {
    const docNumber = tanque.documentsExpired || 0;
    return docNumber > 0;
  }

  /**
   * Retorna la fuente de imagen a mostrar en el Estado del Tanque.
   * @return {string} ruta a imagen.
   */
  getImageSrc(status: string): string {
    return this.masterService.getImageSrc(status);
  }

  /**
   * Refresca la lista de tanques.
   * @private
   * @return {Promise}
   */
  private async updateTanquesList(): Promise<void> {
    this.isGettingTanques = true;
    await this.tanquesService.refreshTanques().then((): void => {
      this.isGettingTanques = false;
    });
  }

  /**
   * Determina, con base al rol del usuario, cuáles botones mostrar.
   * @param {string} button Acción del botón.
   * @return {boolean}
   */
  showButton(button: string): boolean {
    let show: boolean = false;

    if (button === 'create') {
      show = this.isAdmin || this.isCarrier;
    }
    return show;
  }

  /**
   * Determina, con base al rol del usuario, si debe ocultarse una columna de la tabla.
   * @param {string} column Nombre de la columna.
   * @return {Boolean}
   */
  hideColumn(column: string): boolean {
    let hide: boolean = false;

    if (column === 'Transportista') {
      hide = !(this.isAdmin || this.isApprover || this.isViewer);
    }
    return hide;
  }

  /**
   * Aplica a la tabla el filtro ingresado por el usuario.
   */
  applyFilter(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api): void => {
        dtInstance.search(this.searchText).draw();
      });
    }
  }

  ngOnDestroy(): void {
    this.tanquesChangedSubscription.unsubscribe();
    this.onDeleteTanqueListener.unsubscribe();
    this.onCreateTanqueListener.unsubscribe();
    this.onUpdateTanqueListener.unsubscribe();
    console.log('tanques-list.component subscriptions removed.');
  }
}
