<div
  class="container-fluid d-flex flex-column justify-content-center min-vh-100"
  *ngIf="isUploadingDocuments || isCopyingDocuments"
>
  <div class="text-center">
    <app-spinner
      [message]="
        isUploadingDocuments
          ? 'Subiendo documentos a la nube, por favor espere'
          : 'Copiando documentos en la nube, por favor espere'
      "
    ></app-spinner>
  </div>
</div>
<div
  class="container-fluid d-flex flex-column min-vh-100"
  *ngIf="!isUploadingDocuments && !isCopyingDocuments"
>
  <app-header></app-header>
  <div class="row flex-grow-1 mb-5">
    <div class="col-md-12">
      <app-feedbacks></app-feedbacks>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
