<hr />
<div class="row my-2">
  <div class="col-sm-4">
    <h6>
      <img
        class="p-2"
        ngSrc="assets/images/icons/cisternas.svg"
        alt="Cisternas"
        height="50"
        width="50"
      />{{ entityName }}
    </h6>
  </div>
  <div class="col-sm-8 text-end">
    <input
      placeholder="Buscar"
      class="input-search-datatable input-search-datatable-image"
      id="custom-search-input"
      type="text"
      [(ngModel)]="searchText"
      (input)="applyFilter()"
    />
    <button
      id="cisternas-list-new"
      class="btn-btn-create"
      (click)="onNewCisterna()"
      *ngIf="showButton('create')"
    >
      Crear {{ entityName }}
    </button>
    <button
      id="cisternas-list-refresh"
      class="btn btn-light mx-2 btn-refresh btn-refresh-image"
      (click)="onRefresh()"
    ></button>
  </div>
</div>
<div class="row">
  <div class="text-center my-5" *ngIf="isGettingCisternas">
    <app-spinner message="Cargando {{ entityName }}"></app-spinner>
  </div>
  <div class="col-xs-12">
    <div class="table-responsive-sm" *ngIf="!isGettingCisternas">
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Vehículos
        </caption>
        <thead>
          <tr class="text-center">
            <th
              scope="col"
              *ngFor="let attribute of cisternasAttributes"
              [hidden]="hideColumn(attribute)"
            >
              {{ attribute }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cisterna of cisternas">
            <td>
              <a
                id="cisternas-list-{{ cisterna.licensePlate }}"
                (click)="onSelectedCisterna(cisterna)"
                style="color: #0d6efd"
                >{{ cisterna.sapId! | sapId }}</a
              >
            </td>
            <td>
              <span>
                <img
                  [ngSrc]="getImageSrc(cisterna.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ cisterna.status | master : "VEHICLES#STATUSES" }}
            </td>
            <td>{{ cisterna.licensePlate }}</td>
            <td [hidden]="hideColumn('Transportista')">
              {{ cisterna.carrier!.name }}
            </td>
            <td>{{ cisterna.center | master : "CENTERS" }}</td>
            <td>
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngIf="hasToExpireDocuments(cisterna)"
              >
                <span class="badge border rounded-circle text-bg-light">{{
                  cisterna.documentsToExpire
                }}</span>
                por vencer</span
              >
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngIf="hasExpiredDocuments(cisterna)"
              >
                <span class="badge border rounded-circle text-bg-light">{{
                  cisterna.documentsExpired
                }}</span>
                vencido</span
              >
            </td>
            <td>
              <span
                class="badge border rounded-4 text-bg-light mx-1"
                *ngFor="let blockingMotive of cisterna.blockingMotives"
              >
                {{ blockingMotive }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
